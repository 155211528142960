import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { Card, CardBody } from "reactstrap";

import { Input, FormFooterActions } from "@atoms";
import { msgs } from "@config/conf";
import { displayNumberFormat } from "@containers/components/helpers";
import { formatDate } from "@lib/date";

const EditCashLedgerForm = ({ data, onSubmit, redirectUrl }) => {
  const { control, reset, handleSubmit, setValue } = useForm();
  const {
    effectiveDate,
    summaryCashIn,
    summaryCashOut,
    summaryAmount,
    adjustmentAmount,
  } = data || {};

  const [adjustAmount, setAdjustAmount] = useState(0);
  const adjustmentAmountClassName = String(adjustAmount).includes("-")
    ? "text-danger"
    : "";

  useEffect(() => {
    setAdjustAmount(adjustmentAmount);
    reset({
      ...data,
      effectiveDate: formatDate(effectiveDate, "YYYY-MM-DD"),
      summaryCashIn: displayNumberFormat(Math.abs(summaryCashIn || 0), 2),
      summaryCashOut: displayNumberFormat(Math.abs(summaryCashOut || 0), 2),
      summaryAmount: displayNumberFormat(Math.abs(summaryAmount || 0), 2),
      adjustmentAmount: adjustmentAmount
        ? displayNumberFormat(Math.abs(adjustmentAmount || 0), 2)
        : "0.00",
    });
  }, [data]);

  const onChangeAdjustmentAmount = (e) => {
    const { value } = e.target || {};
    setAdjustAmount(value);
  };

  const onBlurAdjustmentAmount = (e) => {
    const { value } = e.target;
    const adjustedAmount = value ? displayNumberFormat(value, 2) : "0.00";
    setValue("adjustmentAmount", adjustedAmount);
  };

  return (
    <Card className="container_width">
      <CardBody className="form form--horizontal form_spacing">
        <Input
          control={control}
          name="effectiveDate"
          label="Effective Date"
          isReadOnly
        />
        <Input
          control={control}
          name="summaryCashIn"
          label="Cash In"
          className={summaryCashIn < 0 ? "text-danger" : ""}
          isReadOnly
        />
        <Input
          control={control}
          name="summaryCashOut"
          label="Cash Out"
          className={summaryCashOut < 0 ? "text-danger" : ""}
          isReadOnly
        />
        <Input
          control={control}
          name="summaryAmount"
          label="Total"
          className={summaryAmount < 0 ? "text-danger" : ""}
          isReadOnly
        />
        <Input
          control={control}
          name="adjustmentAmount"
          label="Adjustment"
          rules={{ required: msgs.fieldError }}
          onBlur={onBlurAdjustmentAmount}
          onChange={onChangeAdjustmentAmount}
          className={adjustmentAmountClassName}
          required
          formatter={{
            numericOnly: true,
            allowNegative: true,
          }}
          maxLength="15"
        />
        <Input
          control={control}
          type="textarea"
          name="adjustmentNotes"
          label="Notes"
          maxLength={2000}
        />
        <FormFooterActions
          linkProps={[{ path: redirectUrl }]}
          buttonProps={[{ onClick: handleSubmit(onSubmit) }]}
        />
      </CardBody>
    </Card>
  );
};

export default EditCashLedgerForm;
