import { Controller } from "react-hook-form";

import {
  addCommasToNumber,
  getNumberFormat,
  sanitizeNumber,
} from "@lib/number";

const Input = (props) => {
  const {
    name,
    control,
    id,
    rules,
    defaultValue,
    type = "text",
    placeholder,
    className = "",
    required,
    isReadOnly,
    onChange,
    label,
    maxLength,
    labelClassName,
    formatter,
    onBlur,
    startIcon,
    endIcon,
    wrapperClassName,
    InfoTooltipComponent,
  } = props || {};

  const {
    numericOnly = false,
    withComma = true,
    allowDecimal,
    allowNegative,
  } = formatter || {};

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({
        field: { onChange: fieldOnChange, onBlur: fieldOnBlur, value = "" },
        formState: { errors },
      }) => {
        const inputClassName = isReadOnly
          ? `cursor-default ${className}`
          : className;

        const commaSeparatedNumericValue =
          (withComma ? addCommasToNumber(value) : value) || "";

        const handleBlur = (event) => {
          if (onBlur) {
            onBlur(event);
          }
          fieldOnBlur(event);
        };

        const handleChange = (event) => {
          if (onChange) {
            onChange(event);
          }
          if (numericOnly) {
            const inputValue = sanitizeNumber(event.target.value, {
              allowDecimal,
              allowNegative,
            });
            const formattedNumberValue = getNumberFormat(inputValue, formatter);
            fieldOnChange(formattedNumberValue);
          } else {
            fieldOnChange(event.target.value);
          }
        };

        return (
          <div className="form__form-group">
            {label ? (
              <label
                htmlFor={id || name}
                className={`form__form-group-label ${labelClassName || ""}`}
              >
                {label}
                {InfoTooltipComponent || null}
                {required ? <span className="text-danger"> *</span> : null}
              </label>
            ) : null}
            <div
              className={`form__form-group-field form__form-group-input-wrap--error-above ${wrapperClassName}`}
            >
              {startIcon ? (
                <div className="form__form-group-icon">{startIcon}</div>
              ) : null}
              {type === "textarea" ? (
                <textarea
                  name={name}
                  className={`textarea ${inputClassName}`}
                  readOnly={isReadOnly}
                  id={id || name}
                  maxLength={maxLength}
                  placeholder={placeholder}
                  onChange={handleChange}
                  value={value || ""}
                  onBlur={handleBlur}
                />
              ) : type === "password" ? (
                <input
                  name={name}
                  id={id || name}
                  readOnly={isReadOnly}
                  className={inputClassName}
                  value={value || ""}
                  onBlur={handleBlur}
                  maxLength={maxLength}
                  onChange={handleChange}
                  placeholder={placeholder}
                  type="password"
                />
              ) : (
                <input
                  name={name}
                  id={id || name}
                  readOnly={isReadOnly}
                  className={inputClassName}
                  value={numericOnly ? commaSeparatedNumericValue : value || ""}
                  onBlur={handleBlur}
                  maxLength={maxLength}
                  onChange={handleChange}
                  placeholder={placeholder}
                  type={type}
                />
              )}
              {endIcon ? <div>{endIcon}</div> : null}
              {errors?.[name] && (
                <span className="form__form-group-error">
                  {errors?.[name]?.message}
                </span>
              )}
            </div>
          </div>
        );
      }}
    />
  );
};

export default Input;
