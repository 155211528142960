import { useMemo } from "react";

import { Card, CardBody, Input } from "reactstrap";

import { TableSearchInput, MultiSelect } from "@atoms";

const commonClass = "btn-w text-light table__search table__search-input mb-0";
const enrollmentStatus = [
  { label: "Pending Issue", value: "Pending Issue" },
  { label: "Pending Premium", value: "Pending Premium" },
];

const EnrollmentsTableSearch = ({ form, setSubmiSearch }) => {
  const { control, setValue, watch } = form;
  const { searchAccount, statusType } = watch();

  const showClearButton = searchAccount || statusType;

  const handleClear = (e) => {
    e.preventDefault();
    setSubmiSearch(true);
    setValue("searchAccount", "");
    setValue("statusType", []);
  };

  const handleClick = () => {
    setSubmiSearch(true);
  };

  const buttons = useMemo(
    () => [
      {
        type: "submit",
        className: `btn-blue ${commonClass}`,
        value: "Filter",
        onClick: handleClick,
      },
      showClearButton
        ? {
            type: "reset",
            className: commonClass,
            value: "Clear",
            onClick: handleClear,
          }
        : null,
    ],
    [showClearButton]
  );

  return (
    <Card className="pb-2">
      <CardBody className="py-2 px-0 table__search mb-0 form-inline">
        <TableSearchInput
          name="searchAccount"
          control={control}
          placeholder="RE Account Number"
          className="w-100"
        />
        <MultiSelect
          name="statusType"
          control={control}
          data={enrollmentStatus}
          variant="table"
          isMulti
        />

        {buttons.map((item) => (
          <Input key={item?.value} {...item} />
        ))}
      </CardBody>
    </Card>
  );
};

export default EnrollmentsTableSearch;
