import { Link } from "react-router-dom";
import { Button } from "reactstrap";

const variantClasses = {
  primary: "btn-outline-primary",
  secondary: "btn-outline-secondary",
  success: "btn-outline-success",
  info: "btn-outline-info",
  warning: "btn-outline-warning",
  danger: "btn-outline-danger",
};

const FormFooterActions = ({ linkProps, buttonProps }) => {
  const filterButtons = buttonProps?.flat()?.filter((item) => item);
  const filterLinks = linkProps?.filter((item) => item);

  return (
    <div className="flex justify-content-between mt-2 w-100">
      <div>
        {filterLinks?.length
          ? filterLinks?.map((item) => {
              const {
                className,
                path,
                labelName = "Go Back",
                variant = "danger",
              } = item || {};
              const hasPath = path ? "visible" : "invisible";
              const linkStyle = `btn m-0 ${variantClasses[variant]} ${hasPath} ${className}`;

              return (
                <Link to={path} className={linkStyle} key={labelName}>
                  {labelName}
                </Link>
              );
            })
          : null}
      </div>
      <div>
        {filterButtons?.length
          ? filterButtons?.map((item) => {
              const {
                onClick,
                className,
                color = "primary",
                labelName = "Save",
                linkUrl = "",
              } = item || {};

              if (linkUrl) {
                return (
                  <Link
                    to={linkUrl}
                    className="btn btn-primary m-0"
                    key={labelName}
                  >
                    {labelName}
                  </Link>
                );
              }
              if (typeof onClick !== "function") {
                return null;
              }
              return (
                <Button
                  onClick={onClick}
                  color={color}
                  className={`m-0 ${className}`}
                  key={labelName}
                >
                  {labelName}
                </Button>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default FormFooterActions;
