import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useForm } from "react-hook-form";
import { Card, CardBody } from "reactstrap";

import { Input, FormFooterActions, Select } from "@atoms";
import { progressStatusOptions } from "@config/conf";
import {
  formatCurrency,
  formatFixedDigits,
  formatToPercentage,
  sanitizeValue,
} from "@containers/components/helpers";
import useApiFetch from "@hooks/useApiFetch";
import { formatDateTime } from "@lib/date";
import { getCapitalizeFirstLetter } from "@lib/string";

import WorkflowModal from "./WorkflowModal";

const validWorkflowTypeIDs = [1001, 1002, 1003, 1004, 1006, 1007, 1008];
const workflowStatusOptions = progressStatusOptions?.slice(0, 2);

const WorkflowsForm = ({ id, data, goBackUrl, gotoWorkflowTypeIdUrl }) => {
  const modalRef = useRef(null);
  const { control, reset, handleSubmit } = useForm();
  const { get, put } = useApiFetch();
  const [workflowSubstatus, setWorkflowSubstatus] = useState([]);
  const isEditableField = data?.workflowTypeID === 1009;

  useEffect(() => {
    const getWorkflowSubStatus = async () => {
      const response = await get("workflows/enrollmentStatuses");
      const data = response?.map((item) => ({
        value: item?.statusID,
        label: item?.statusName
          .replace(/([a-z])([A-Z])/g, "$1 $2")
          .replace(/^./, (str) => str.toUpperCase()),
      }));
      setWorkflowSubstatus(data);
    };
    getWorkflowSubStatus();
  }, []);

  const workflowFormFields = useMemo(
    () => [
      { name: "workflowNumber", label: "Workflow Number" },
      { name: "rootWorkflowNumber", label: "Root Workflow #" },
      { name: "workflowType", label: "Workflow Type" },
      { name: "workflowStartDate", label: "Start Date" },
      { name: "workflowEndDate", label: "End Date" },
      { name: "activityPercent", label: "Activity Percent" },
      {
        name: "activityAmount",
        label: "Activity Amount",
        className: data?.activityAmount < 0 ? "text-danger" : "",
      },
      {
        name: "settledAmount",
        label: "Settled Amount",
        className: data?.settledAmount < 0 ? "text-danger" : "",
      },
      { name: "workflowDescription", label: "Workflow Description" },
      { name: "reAccountNumber", label: "Re Account #" },
      { name: "policyNumber", label: "Policy #" },
      { name: "carrierName", label: "Carrier" },
      { name: "productName", label: "Product" },
      { name: "planName", label: "Plan" },
      { name: "participantName", label: "Participant" },
    ],
    [data?.settledAmount, data?.activityAmount]
  );

  useEffect(() => {
    const {
      alertDate,
      workflowNumber,
      rootWorkflowNumber,
      workflowStartDate,
      workflowEndDate,
      activityPercent,
      activityAmount,
      settledAmount,
      statusDate,
      reAccountNumber,
      subStatus,
    } = data || {};

    const subStatusValue = getCapitalizeFirstLetter(subStatus);
    const subStatusOption = isEditableField
      ? workflowSubstatus?.find((item) => item?.label === subStatusValue)?.value
      : subStatus;

    reset({
      ...data,
      subStatus: subStatusOption,
      alertDate: formatDateTime(alertDate),
      workflowNumber: workflowNumber
        ? formatFixedDigits(12, workflowNumber)
        : "",
      rootWorkflowNumber: rootWorkflowNumber
        ? formatFixedDigits(12, rootWorkflowNumber)
        : "",
      workflowStartDate: formatDateTime(workflowStartDate),
      workflowEndDate: formatDateTime(workflowEndDate),
      activityPercent: activityPercent
        ? formatToPercentage(Math.abs(activityPercent))
        : "0.00%",
      activityAmount: activityAmount
        ? formatCurrency(Math.abs(activityAmount), 2)
        : "$0.00",
      settledAmount: settledAmount
        ? formatCurrency(Math.abs(settledAmount), 2)
        : "$0.00",
      statusDate: formatDateTime(statusDate),
      reAccountNumber: reAccountNumber !== 0 ? reAccountNumber : "",
    });
  }, [data, isEditableField, workflowSubstatus]);

  const onSubmit = useCallback(
    (values) => {
      Object.keys(values).forEach((key) => {
        if (typeof values[key] === "string") {
          values[key] = values[key].trim();
        }
      });

      const payload = isEditableField
        ? {
            status: values?.status,
            subStatus: Number(values?.subStatus),
          }
        : {};

      const payloadValues = {
        ...payload,
        notes: sanitizeValue(values?.notes),
      };

      put(`workflows/${id}`, payloadValues, { redirectUrl: goBackUrl });
    },
    [goBackUrl, id, isEditableField]
  );

  const buttonActions = {
    buttonProps: [
      data?.status === "Error"
        ? [
            {
              labelName: "Resubmit",
              onClick: modalRef.current?.toggleResubmitModal,
            },
            {
              labelName: "Cancel",
              onClick: modalRef.current?.toggleFailModal,
            },
          ]
        : [],

      data?.alertSet
        ? {
            labelName: "Clear Alert",
            onClick: modalRef.current?.toggleAlertModal,
          }
        : null,

      validWorkflowTypeIDs.includes(data?.workflowTypeID)
        ? {
            labelName: "Detail",
            linkUrl: gotoWorkflowTypeIdUrl,
          }
        : null,

      { onClick: handleSubmit(onSubmit) },
    ],
  };

  return (
    <>
      <WorkflowModal
        id={id}
        goBackUrl={goBackUrl}
        workflowTypeID={data?.workflowTypeID}
        ref={modalRef}
      />
      <Card className="container_width">
        <CardBody className="form form--horizontal form_spacing">
          {data?.alertSet ? (
            <>
              <Input
                control={control}
                name="alertDate"
                label="Alert Date"
                isReadOnly
                labelClassName="text-danger"
              />
              <Input
                control={control}
                name="alertMessage"
                label="Alert Message"
                isReadOnly
                labelClassName="text-danger"
              />
            </>
          ) : null}
          {workflowFormFields?.map(({ label, name, className }) => (
            <Input
              key={name}
              control={control}
              name={name}
              className={className}
              label={label}
              isReadOnly
            />
          ))}
          {isEditableField ? (
            <>
              <Select
                control={control}
                name="status"
                label="Status"
                options={workflowStatusOptions}
              />
              <Select
                control={control}
                name="subStatus"
                label="Sub Status"
                options={workflowSubstatus}
              />
            </>
          ) : (
            <>
              <Input
                control={control}
                name="status"
                label="Status"
                isReadOnly
              />
              <Input
                control={control}
                name="subStatus"
                label="Sub Status"
                isReadOnly
              />
            </>
          )}
          <Input
            control={control}
            name="statusDate"
            label="Status Updated"
            isReadOnly
          />
          <Input
            control={control}
            name="statusMessage"
            label="Status Message"
            isReadOnly
          />
          <Input
            control={control}
            name="userName"
            label="Created By"
            isReadOnly
          />
          <Input
            type="textarea"
            control={control}
            name="notes"
            label="Notes"
            maxLength="5000"
          />
          <FormFooterActions
            linkProps={[{ path: goBackUrl }]}
            {...buttonActions}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default WorkflowsForm;
