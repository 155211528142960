import React, { useCallback, useEffect, useState } from "react";

import { Container } from "reactstrap";

import { sanitizeValue } from "@containers/components/helpers";
import FormSpinner from "@containers/FormSpinner/FormSpinner";
import useApiFetch from "@hooks/useApiFetch";
import useToast from "@hooks/useToast";

import ConfigForm from "./components/ConfigForm";

const Config = () => {
  const { get, put, isPending } = useApiFetch();
  const [reConfigData, setReConfigData] = useState({});
  const { validateRequiredField } = useToast();

  const data = reConfigData?.data?.[0] || {};

  const getREConfig = useCallback(async () => {
    const response = await get("reconfig/get");
    setReConfigData(response?.data);
  }, []);

  useEffect(() => {
    getREConfig();
  }, [getREConfig]);

  const handleConfig = (values) => {
    Object.keys(values).forEach((key) => {
      if (typeof values[key] === "string") {
        values[key] = sanitizeValue(values[key]);
      }
    });

    const { smtpHost, smtpPort, smtpUserName, smtpPassword, tokenTimeoutMins } =
      values || {};

    const isValid = validateRequiredField({
      smtpHost,
      smtpPort,
      "smtp UserName": smtpUserName,
      "smtp Password": smtpPassword,
    });

    if (!isValid) {
      return;
    }

    const payloadValues = {
      ...values,
      tokenTimeoutMins: Number(tokenTimeoutMins),
      smtpPort: Number(smtpPort),
    };

    put(`reconfig/reconfig/${data?.reConfigID}`, payloadValues);
  };

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center text-green">System Configuration</h3>
      <FormSpinner requesting={isPending} />
      <ConfigForm data={data} onSubmit={handleConfig} />
    </Container>
  );
};

export default Config;
