import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Button, Card, CardBody } from "reactstrap";

import { Input } from "@atoms";
import { msgs } from "@config/conf";

const workflow1001ResubmitFormFields = [
  {
    label: "Workflow Number",
    name: "workflowNumber",
    readOnly: true,
  },
  {
    label: "Workflow Type",
    name: "workflowType",
    readOnly: true,
  },
  {
    label: "RE Account #",
    name: "reAccountNumber",
    readOnly: true,
  },
  {
    label: "Policy #",
    name: "policyNumber",
    readOnly: true,
  },
  {
    label: "Participant",
    name: "participantName",
    readOnly: true,
  },

  {
    label: "Carrier",
    name: "carrier",
    readOnly: true,
  },
  {
    label: "Product",
    name: "product",
    readOnly: true,
  },
  {
    label: "Plan",
    name: "plan",
    readOnly: true,
  },
  {
    label: "Social Security #",
    name: "ssNumber",
    maxLength: 9,
  },
  {
    label: "Prefix",
    name: "prefix",
    maxLength: 20,
  },
  {
    label: "First Name",
    name: "firstName",
    maxLength: 100,
    isRequired: true,
  },
  {
    label: "Middle Name",
    name: "middleName",
    maxLength: 100,
  },
  {
    label: "Last Name",
    name: "lastName",
    maxLength: 100,
    isRequired: true,
  },
  {
    label: "Suffix",
    name: "suffix",
    maxLength: 20,
  },
  {
    label: "Email",
    name: "email",
    maxLength: 100,
  },
  {
    label: "Phone Number",
    name: "phoneNumber",
    maxLength: 10,
  },
];

const ResubmitWFT1001Form = () => {
  const { control } = useForm();

  return (
    <Card className="container_width">
      <CardBody className="form form--horizontal form_spacing">
        {workflow1001ResubmitFormFields?.map(
          ({ label, name, isRequired, maxLength, readOnly }) => {
            const requiredProps = isRequired
              ? {
                  control,
                  required: true,
                  rules: { required: msgs.fieldError },
                }
              : { control };
            return (
              <Input
                {...requiredProps}
                label={label}
                name={name}
                key={name}
                maxLength={maxLength}
                isReadOnly={readOnly}
              />
            );
          }
        )}
        <div className="w-100 flex justify-content-between">
          <Link
            className="btn btn-outline-danger"
            to={`/workflows/${"id"}/edit?from=${"page"}`}
          >
            Cancel
          </Link>
          <Button color="primary">Resubmit</Button>
        </div>
      </CardBody>
    </Card>
  );
};

export default ResubmitWFT1001Form;
