import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useForm } from "react-hook-form";
import { Container } from "reactstrap";

import { sanitizeValue } from "@containers/components/helpers";
import useApiFetch from "@hooks/useApiFetch";
import useQueryParams from "@hooks/useQueryParams";

import PlanProductForm from "../components/PlanProductForm";

const defaultValues = {
  productID: localStorage.getItem("productID"),
};

const queryPath = ["from", "search", "sortPlanName", "sortStatus", "pageSize"];

const AddPlanProduct = (props) => {
  const {
    match: {
      params: { planId },
    },
  } = props;

  const addPlanProductForm = useForm({
    mode: "onChange",
    defaultValues,
  });

  const { get, isPending, post } = useApiFetch();
  const [data, setData] = useState({
    plans: {},
    products: {},
  });

  const { getQueryParams, buildQueryParams } = useQueryParams();

  const { from: pageNumber, ...restParams } = getQueryParams(queryPath);

  const dataProps =
    {
      plansData: data?.plans,
      productsData: { products: data?.products, requesting: isPending },
    } || {};

  const getProductData = useCallback(async () => {
    const response = await get("products-and-pios/get");
    setData((prev) => ({ ...prev, products: response }));
  }, []);

  const getPlan = useCallback(async () => {
    const response = await get(`plans/${planId}`);
    setData((prev) => ({ ...prev, plans: response }));
  }, []);

  useEffect(() => {
    getProductData();
    getPlan();
  }, [getPlan, getProductData]);

  const navigatePlanDashboard = useMemo(() => {
    const params = buildQueryParams({
      page: pageNumber,
      id: planId,
      ...restParams,
    });
    return `/plans?${params}`;
  }, [pageNumber, restParams]);

  const handleFormSubmit = useCallback((values) => {
    const payloadValues = {
      ...values,
      carrierPlanIdentifier: sanitizeValue(values.carrierPlanIdentifier),
      status: "Active",
    };
    post(`plans/${planId}/planproducts`, payloadValues, {
      redirectUrl: navigatePlanDashboard,
    });
  }, []);

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Plan Product Detail</h3>
      <PlanProductForm
        form={addPlanProductForm}
        data={dataProps}
        planId={planId}
        onSubmit={handleFormSubmit}
        loadingResponse={isPending}
      />
    </Container>
  );
};

export default AddPlanProduct;
