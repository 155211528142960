import { useCallback, useEffect, useMemo, useState } from "react";

import { useForm, useFieldArray } from "react-hook-form";
import { Badge } from "reactstrap";

import { Spinner, Table, TableDashboard, FormFooterActions } from "@atoms";
import useApiFetch from "@hooks/useApiFetch";
import useQueryParams from "@hooks/useQueryParams";
import useToast from "@hooks/useToast";

const queryParams = [
  "page",
  "search",
  "pageSize",
  "sortPlanName",
  "sortStatus",
];

const RecordkeeperPlanIds = ({
  match: {
    params: { planId },
  },
}) => {
  const [companyDetails, setCompanyDetails] = useState([]);
  const [planName, setPlanName] = useState("");
  const { control, getValues, setValue } = useForm();
  const { get, post, isPending } = useApiFetch();
  const { showErrorToast } = useToast();
  const { getQueryParams, buildQueryParams } = useQueryParams();

  const queryPath = getQueryParams(queryParams);
  const { append, remove } = useFieldArray({
    control,
    name: "companyDetailsTable",
  });

  const { companyDetailsTable } = getValues();

  const getRkCompanies = useCallback(async () => {
    const response = await get(`recordkeepers/GetRKCompanies?PlanID=${planId}`);
    setCompanyDetails(response?.rkPlanData);
  }, []);

  useEffect(() => {
    (async () => {
      const response = await get(`plans/${planId}`);
      setPlanName(response?.planName);
    })();

    getRkCompanies();
  }, []);

  useEffect(() => {
    if (companyDetails?.length) {
      setValue("companyDetailsTable", companyDetails);
    }
  }, [companyDetails?.length]);

  const handleSubmit = useCallback(async () => {
    const payloadValues = companyDetailsTable?.map((item) => ({
      ...item,
      planID: planId,
    }));

    if (companyDetailsTable?.length) {
      await post("recordkeepers/CreateUpdateRKCompany", {
        rkPlanData: payloadValues,
      });
      getRkCompanies();
    } else {
      showErrorToast("At least one record is required");
    }
  }, [companyDetailsTable?.length]);

  const handleDeleteRow = async (row) => {
    const {
      id: rowIndexId,
      original: { planCompanyID },
    } = row;

    if (companyDetailsTable?.length > 1) {
      await post(
        `recordkeepers/DeleteRKCompany?PlanCompanyID=${planCompanyID}`
      );
      remove(rowIndexId);
    } else {
      showErrorToast("Deletion of all items is not permitted");
    }
  };

  const column = [
    {
      id: "rkPlanIdentifier",
      header: "Recordkeeper Identifier",
      accessorKey: "rkPlanIdentifier",
    },
    {
      id: "rkCompanyName",
      header: "Plan Name",
      accessorKey: "rkCompanyName",
    },
    {
      id: "actions",
      header: "",
      size: 300,
      cell: ({ row }) => {
        const handleDelete = () => handleDeleteRow(row);
        return (
          <div className="pr-2 column-end">
            <Badge
              color="danger"
              className="pointer my-2"
              onClick={handleDelete}
            >
              Delete
            </Badge>
          </div>
        );
      },
    },
  ];

  const tableFooter = useMemo(() => {
    const addNewRow = () => append({ rkPlanIdentifier: "", rkCompanyName: "" });
    const buttonActions = {
      buttonProps: [
        { labelName: "Add", onClick: addNewRow },
        { onClick: handleSubmit },
      ],
    };

    return (
      <FormFooterActions
        linkProps={[
          { path: `/plans/${planId}/edit?${buildQueryParams(queryPath)}` },
        ]}
        {...buttonActions}
      />
    );
  }, [handleSubmit]);

  return (
    <TableDashboard pageTitle="Recordkeeper Plan ID’s" subtitle={planName}>
      <Spinner requesting={isPending}>
        <Table
          columns={column}
          data={companyDetailsTable}
          hidePagination
          footer={tableFooter}
          isEditable
          editableProps={{
            control,
            tableName: "companyDetailsTable",
          }}
        />
      </Spinner>
    </TableDashboard>
  );
};

export default RecordkeeperPlanIds;
