import { useEffect, useMemo, useState } from "react";

import { Card, CardBody, Input } from "reactstrap";

import { DateRangePicker, TableSearchInput, MultiSelect } from "@atoms";
import { progressStatusOptions } from "@config/conf";
import { filterOptions } from "@containers/components/helpers";
import useApiFetch from "@hooks/useApiFetch";

const commonClass = "btn-w text-light table__search table__search-input mb-0";

const WorkflowsTableSearch = ({ form, setSubmitSearch }) => {
  const { control, setValue, watch } = form;

  const { get } = useApiFetch();
  const [workflowTypes, setWorkflowTypes] = useState([]);

  const { searchWorkflow, workflowType, workflowStatus, fromDate, toDate } =
    watch();

  const shouldShowClearButton =
    searchWorkflow || workflowType || fromDate || toDate || workflowStatus;

  useEffect(() => {
    const fetchWorkflowTypes = async () => {
      const response = await get("workflowtypes/distinctwt");
      if (response?.data) {
        setWorkflowTypes(response.data);
      }
    };

    fetchWorkflowTypes();
  }, []);

  const handleClear = (e) => {
    e.preventDefault();
    setSubmitSearch(true);
    setValue("searchWorkflow", "");
    setValue("workflowType", "");
    setValue("workflowStatus", "");
    setValue("fromDate", null);
    setValue("toDate", null);
  };

  const workflowTypesOptions = filterOptions(
    workflowTypes,
    "workflowType",
    "workflowType"
  );

  const handleOnClick = () => {
    setSubmitSearch(true);
  };

  const { dateRangeFields, button } = useMemo(
    () => ({
      dateRangeFields: [
        {
          name: "fromDate",
          placeholder: "From Date (MM/DD/YYYY)",
        },
        {
          name: "toDate",
          placeholder: "To Date (MM/DD/YYYY)",
        },
      ],
      button: [
        {
          type: "submit",
          className: `btn-blue ${commonClass}`,
          value: "Filter",
          onClick: handleOnClick,
        },
        ...(shouldShowClearButton
          ? [
              {
                type: "reset",
                className: commonClass,
                value: "Clear",
                onClick: handleClear,
              },
            ]
          : []),
      ],
    }),
    [shouldShowClearButton]
  );

  return (
    <Card className="pb-2">
      <CardBody className="py-2 px-0 table__search form-inline">
        <TableSearchInput
          control={control}
          name="searchWorkflow"
          placeholder="WF#, RE Account Number or Policy Number"
          maxLength={40}
          style={{ maxWidth: "275px", width: "100%" }}
        />
        <MultiSelect
          name="workflowType"
          control={control}
          data={workflowTypesOptions}
          variant="table"
          isMulti
        />
        <MultiSelect
          name="workflowStatus"
          control={control}
          variant="table"
          data={progressStatusOptions}
        />
        {dateRangeFields.map(({ name, placeholder }) => (
          <DateRangePicker
            key={name}
            name={name}
            control={control}
            placeholder={placeholder}
            datePickerVariant="table"
          />
        ))}
        {button.map((item) => (
          <Input key={item?.value} {...item} />
        ))}
      </CardBody>
    </Card>
  );
};

export default WorkflowsTableSearch;
