import { useCallback, useEffect, useMemo, useState } from "react";

import classnames from "classnames";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import { FormFooterActions, Input, Modal } from "@atoms";
import { formatCurrency, ssnFormat } from "@containers/components/helpers";
import useApiFetch from "@hooks/useApiFetch";
import { formatDate, formatDateTime } from "@lib/date";

import AccountInformation from "./AccountInformation";
import AddressTable from "./AddressTable";
import DocumentVaultTable from "./DocumentVaultTable";
import PioElectionsTable from "./PioElectionsTable";
import PipRatesTable from "./PipRatesTable";
import PolicyInformation from "./PolicyInformation";
import SubAccountsTable from "./SubAccountsTable";

const accountsTabs = [
  "Account Information",
  "Policy Information",
  "Subaccounts",
  "Addresses",
  "PIO Elections",
  "PIP Rates",
  "Document Vault",
];

const accountFormFields = [
  { label: "Account Number", name: "reAccountNumber" },
  { label: "Account Status", name: "accountStatus" },
  { label: "Participant Name", name: "participantName" },
  { label: "SSN", name: "ssNumber" },
  { label: "Plan Name", name: "planName" },
  { label: "Product Name", name: "productName" },
  { label: "Tax Code", name: "taxCode" },
];

const EditAccountForm = ({ data, id, from, commonPath, onSubmit }) => {
  const accountsForm = useForm();
  const history = useHistory();
  const { put } = useApiFetch();
  const [activeTab, setActiveTab] = useState("1");
  const [confirmModal, setConfirmModal] = useState(false);

  const { control, reset, handleSubmit } = accountsForm;

  const navigateToNewTxnPage = () =>
    history.push(
      `/accounts/${id}/withdrawal/create?from=${from}&${commonPath}`
    );

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    const formattedCurrencyValue = (field, value) => ({
      [field]: value && value !== 0 ? formatCurrency(value) : "$ 0.00",
    });

    if (data) {
      const formattedData = {
        ...data,
        ssNumber: ssnFormat(data.ssNumber),

        // account information
        dob: formatDate(data.dob),
        accountCreatedON: formatDateTime(data.accountCreatedON),
        custodianAccountDate: formatDateTime(data.custodianAccountDate),

        // policy information
        policyPOVDate: formatDate(data.policyPOVDate),
        policyApplicationDate: formatDate(data.policyApplicationDate),
        policyIssueDate: formatDate(data.policyIssueDate),
        policySurrenderDate: formatDate(data.policySurrenderDate),
        policySurrenderFeeExpDate: formatDate(data.policySurrenderFeeExpDate),
        policyTerminationDate: formatDate(data.policyTerminationDate),
        policyMaturityDate: formatDate(data.policyMaturityDate),
        policyMaxAnnuitizationDate: formatDate(data.policyMaxAnnuitizationDate),
        policyAnnuitizationDate: formatDate(data.policyAnnuitizationDate),
        policyRepatriationDate: formatDate(data.policyRepatriationDate),

        ...formattedCurrencyValue(
          "policyAccumulationValue",
          data?.policyAccumulationValue || 0
        ),
        ...formattedCurrencyValue(
          "policyGuaranteedBenefitBase",
          data?.policyGuaranteedBenefitBase || 0
        ),
        ...formattedCurrencyValue("policyRMDValue", data?.policyRMDValue || 0),
        ...formattedCurrencyValue(
          "policySurrenderValue",
          data?.policySurrenderValue || 0
        ),
        ...formattedCurrencyValue(
          "policyYearEndValue",
          data?.policyYearEndValue || 0
        ),
        ...formattedCurrencyValue(
          "policyActuarialPV",
          data?.policyActuarialPV || 0
        ),
        ...formattedCurrencyValue(
          "policyTotalPremiumPaid",
          data?.policyTotalPremiumPaid || 0
        ),
        ...formattedCurrencyValue(
          "policyTotalWithdrawals",
          data?.policyTotalWithdrawals || 0
        ),
        ...formattedCurrencyValue("povAdjustment", data?.povAdjustment || 0),
      };

      reset(formattedData);
    }
  }, [data]);

  const tabContents = useMemo(
    () => [
      {
        name: "accountInformation",
        component: <AccountInformation control={control} />,
      },
      {
        name: "policyInformation",
        component: <PolicyInformation control={control} />,
      },
      { name: "subAccounts", component: <SubAccountsTable id={id} /> },
      { name: "address", component: <AddressTable id={id} /> },
      { name: "pioElections", component: <PioElectionsTable id={id} /> },
      { name: "pipRates", component: <PipRatesTable id={id} /> },
      { name: "DocumentVault", component: <DocumentVaultTable id={id} /> },
    ],
    []
  );

  const updatePolicyEnrollment = useCallback(() => {
    put(`reaccounts/FreeLookCancelReAccount/${id}`, null, {
      redirectUrl: `/accounts?from=${from}&${commonPath}`,
    });
  }, []);

  const buttonActions = useCallback((tabName) => {
    const isAccountsTab = tabName === "accountInformation";
    const isPolicyInformation = tabName === "policyInformation";

    return {
      buttonProps: [
        isAccountsTab
          ? [
              { labelName: "New Txn", onClick: navigateToNewTxnPage },
              { labelName: "Save", onClick: handleSubmit(onSubmit) },
            ]
          : isPolicyInformation
            ? [
                {
                  labelName: "Free Look Cancel",
                  onClick: () => setConfirmModal(true),
                },
              ]
            : null,
      ],
    };
  }, []);

  return (
    <div className="form form--horizontal">
      {confirmModal ? (
        <Modal
          isModalOpen={confirmModal}
          setIsModalOpen={setConfirmModal}
          content="This will set the policy enrollment status to cancelled and make the policy inactive. Are you sure you want to continue?"
          variant="warning"
          iconName="lnr-warning"
          modalButtons={[
            {
              name: "Cancel",
              color: "danger",
              onClick: () => setConfirmModal(false),
            },
            {
              name: "Confirm",
              color: "primary",
              onClick: updatePolicyEnrollment,
            },
          ]}
        />
      ) : null}
      <Card>
        <CardBody className="px-1 py-3">
          {accountFormFields?.map(({ label, name }) => (
            <Input
              className="mb-2"
              key={label}
              control={control}
              name={name}
              isReadOnly
              label={label}
            />
          ))}
        </CardBody>
      </Card>
      <Card>
        <CardBody className="tabs tabs--justify tabs--bordered-top">
          <Nav tabs>
            {accountsTabs?.map((tab, index) => (
              <NavItem key={tab}>
                <NavLink
                  className={classnames({
                    active: activeTab === `${index + 1}`,
                  })}
                  onClick={() => toggle(`${index + 1}`)}
                >
                  {tab}
                </NavLink>
              </NavItem>
            ))}
          </Nav>

          <TabContent activeTab={activeTab}>
            {tabContents?.map(({ component, name }, index) => {
              return (
                <TabPane tabId={`${index + 1}`} key={name}>
                  {component}
                  <FormFooterActions
                    linkProps={[
                      { path: `/accounts?page=${from}&${commonPath}` },
                    ]}
                    {...buttonActions(name)}
                  />
                </TabPane>
              );
            })}
          </TabContent>
        </CardBody>
      </Card>
    </div>
  );
};

export default EditAccountForm;
