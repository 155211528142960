const iconUrl = `${process.env.PUBLIC_URL}/img/burger.svg`;

const TopbarSidebarButton = ({
  changeMobileSidebarVisibility,
  changeSidebarVisibility,
}) => {
  const deviceType = [
    {
      id: "desktop",
      className: "topbar__button--desktop",
      callbackFn: changeSidebarVisibility,
    },
    {
      id: "mobile",
      className: "topbar__button--mobile",
      callbackFn: changeMobileSidebarVisibility,
    },
  ];

  return (
    <>
      {deviceType?.map(({ callbackFn, className, id }) => (
        <button
          key={id}
          className={`topbar__button ${className}`}
          type="button"
          onClick={callbackFn}
        >
          <img
            height={16}
            width={16}
            src={iconUrl}
            alt="sidebar menu"
            className="topbar__button-icon"
          />
        </button>
      ))}
    </>
  );
};

export default TopbarSidebarButton;
