import { useCallback, useEffect, useState } from "react";

import { Container } from "reactstrap";

import FormSpinner from "@containers/FormSpinner/FormSpinner";
import useApiFetch from "@hooks/useApiFetch";

import WorkflowTypeIdForm from "./WorkflowTypeIdForm";

const WorkflowTypeID1004 = ({
  match: {
    params: { workflowId },
  },
}) => {
  const { get, isPending } = useApiFetch();

  const [fetchData, setFetchData] = useState({});

  const getData = useCallback(async () => {
    const data = await get(`workflows/workflowdetail1004/${workflowId}`);
    setFetchData(data?.data?.[0]);
  }, [workflowId]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Participant Elections Update</h3>

      <FormSpinner requesting={isPending} />
      <WorkflowTypeIdForm id={workflowId} data={fetchData} hasTable />
    </Container>
  );
};

export default WorkflowTypeID1004;
