import { Typography } from "@material-ui/core";

import { urls } from "@config/url";
import { imagePath } from "@config/url/images";
import "@scss/containers/annuity-page.scss";
import { getImageUrl, navigateToUrl } from "@utils/url";

const logo = getImageUrl(imagePath.allianzLogo);
const annuityUrl = navigateToUrl();
const banner = getImageUrl(imagePath.allianzBanner);

const AnnuityDocumentsPage = () => (
  <div className="annuity_document_wrapper mt-3">
    <div className="left_container">
      <a target="_blank" rel="noreferrer" href={urls.allianzUrl}>
        <div className="learn_more p-2">
          <div>
            <img src={logo} alt="logo" />
          </div>
          <p className="btn annuity_btn m-0 text-white">Learn more</p>
        </div>
        <img src={banner} alt="banner" />
      </a>
    </div>
    <div className="divider" />
    <div className="right_container text-white">
      <a href={annuityUrl} target="_blank" rel="noreferrer">
        <Typography
          variant="h5"
          align="center"
          className="current_rates font-weight-bold text-white"
        >
          View Guide to current rates and other annuity documents
        </Typography>
      </a>
    </div>
  </div>
);
export default AnnuityDocumentsPage;
