import { useCallback, useEffect, useMemo, useState } from "react";

import { Card, CardBody } from "reactstrap";

import { Input, Select, Checkbox, FormFooterActions } from "@atoms";
import { msgs, statusOptions } from "@config/conf";
import {
  sanitizeValue,
  validateMaxValue,
  validateNumberField,
} from "@containers/components/helpers";
import FormSpinner from "@containers/FormSpinner/FormSpinner";
import useApiFetch from "@hooks/useApiFetch";
import useQueryParams from "@hooks/useQueryParams";

const { fieldError } = msgs || {};

const queryPath = ["from", "sortProductName", "sortCarrierName", "pageSize"];

const ProductFactorForm = (props) => {
  const {
    form,
    productID = "",
    productFactorID = "",
    loadingResponse,
  } = props || {};

  const { get, put, post, isPending } = useApiFetch();
  const [productData, setProductData] = useState({});
  const { getQueryParams, buildQueryParams } = useQueryParams();

  const { from: page, ...restParams } = getQueryParams(queryPath);

  const { control, handleSubmit, setValue, watch } = form || {};

  const isRequesting = loadingResponse || isPending;
  const { joint, jointPercent } = watch();

  const getProduct = useCallback(async () => {
    const response = await get(`products/${productID}`);
    setProductData(response);
  }, []);

  useEffect(() => {
    getProduct();
  }, [getProduct]);

  useEffect(() => {
    if (productData?.productID) {
      setValue("productName", productData?.productName);
    }
    setValue("jointPercent", joint ? jointPercent : "0");
  }, [productData?.productID, joint]);

  const goBackUrl = useMemo(() => {
    const queryParams = buildQueryParams({ page, ...restParams });

    return `/products?${
      productFactorID ? `id=${productID}&` : ""
    }${queryParams}`;
  }, [page, restParams]);

  const onSave = useCallback((values) => {
    const {
      jointPercent,
      deferralYears,
      factorName,
      factorID,
      refundable,
      joint,
      selectable,
      status,
    } = values || {};

    const payloadValues = {
      deferralYears: Number(deferralYears),
      jointPercent: Number(jointPercent),
      factorName: sanitizeValue(factorName),
      factorID: factorID ? sanitizeValue(factorID) : undefined,
      productID,
      refundable,
      joint,
      selectable,
      status,
    };

    factorID
      ? put(`products/productFactor/${productFactorID}`, payloadValues, {
          redirectUrl: goBackUrl,
        })
      : post("products/productfactors", payloadValues, {
          redirectUrl: goBackUrl,
        });
  }, []);

  const commonProps = useMemo(
    () => ({
      control,
      rules: {
        required: fieldError,
      },
    }),
    [control]
  );

  return (
    <Card className="product_factor_form_wrapper">
      <FormSpinner requesting={isRequesting} />
      <CardBody className="form form--horizontal product_page_wrapper">
        <Input
          {...commonProps}
          name="productName"
          label="Product Name"
          required
          isReadOnly
        />
        <Input
          {...commonProps}
          name="factorName"
          label="Factor Name"
          required
        />
        <Checkbox control={control} name="joint" label="Joint" />
        <Input
          control={control}
          rules={{
            validate: (value) => validateMaxValue(value),
          }}
          name="jointPercent"
          label="Joint Percent"
          isReadOnly={!joint}
          formatter={{ numericOnly: true }}
        />
        <Checkbox
          {...commonProps}
          name="refundable"
          required
          label="Refundable"
        />
        <Input
          control={control}
          rules={{
            required: fieldError,
            validate: (value) => validateNumberField(value, fieldError),
          }}
          name="deferralYears"
          label="Deferral Years"
          required
          formatter={{ numericOnly: true }}
        />
        <Checkbox control={control} name="selectable" label="Selectable" />
        <Select
          {...commonProps}
          name="status"
          label="Status"
          required
          options={statusOptions}
        />
        {productFactorID && (
          <Input
            control={control}
            name="lastUpdatedOn"
            label="Last Updated"
            isReadOnly
          />
        )}
        <FormFooterActions
          linkProps={[{ path: goBackUrl }]}
          buttonProps={[{ onClick: handleSubmit(onSave) }]}
        />
      </CardBody>
    </Card>
  );
};

export default ProductFactorForm;
