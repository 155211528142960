import { useCallback, useEffect, useState } from "react";

import { Spinner, Table } from "@atoms";
import { pagination } from "@config/conf";
import { getPagination } from "@containers/components/helpers";
import useApiFetch from "@hooks/useApiFetch";
import useQueryParams from "@hooks/useQueryParams";
import { formatDateTime } from "@lib/date";

const queryPath = ["pageSize", "addressesPage"];

const addressColumns = [
  {
    id: "addressType",
    header: "Type",
    accessorKey: "addressType",
    enableSorting: false,
  },
  {
    id: "address1",
    header: "Address 1",
    accessorKey: "address1",
    enableSorting: false,
    size: "230px",
  },
  {
    id: "address2",
    header: "Address 2",
    accessorKey: "address2",
    enableSorting: false,
  },
  {
    id: "city/state/zip",
    header: "City/State/Zip",
    cell: (tableRow) => {
      const {
        city = "",
        stateCode = "",
        zip = "",
      } = tableRow?.row?.original || {};
      const cityStateValue = `${city}, ${stateCode} ${zip}`;
      return cityStateValue;
    },
  },
  {
    id: "country",
    header: "Country",
    accessorKey: "country",
    enableSorting: false,
  },
  {
    id: "status",
    header: "Status",
    accessorKey: "status",
    enableSorting: false,
  },
  {
    id: "lastUpdated",
    header: "Last Updated",
    cell: (tableRow) => {
      const { lastUpdated } = tableRow?.row?.original || {};
      return formatDateTime(lastUpdated);
    },
  },
];

const AddressTable = ({ id }) => {
  const { getQueryParams } = useQueryParams();
  const { get, isPending } = useApiFetch();
  const [fetchData, setFetchData] = useState({});

  const { pageSize: pageSizeQuery, addressesPage } = getQueryParams(queryPath);
  const [pageSize, setPageSize] = useState(pageSizeQuery || pagination.length);

  const { offset, pageNumber } = getPagination(pageSize, addressesPage) || {};

  const { data: tableData = [], recordCount = 0 } = fetchData || {};

  const getData = useCallback(async () => {
    const response = await get(
      `reaccounts/addresses/${id}?Offset=${offset}&Limit=${pageSize}`
    );
    setFetchData(response);
  }, [offset, pageSize]);

  useEffect(() => {
    getData();
  }, [getData]);

  const pageProps = {
    total: recordCount,
    pageSize,
    setPageSize,
    pageNumber,
    fromPage: pageNumber,
    pageName: "addressesPage",
  };

  return (
    <Spinner requesting={isPending}>
      <Table
        columns={addressColumns}
        data={tableData}
        pageProps={pageProps}
        noPadding
        noBottomBorder
      />
    </Spinner>
  );
};

export default AddressTable;
