import { useCallback, useEffect, useMemo, useState } from "react";

import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

import { Input, Select, FormFooterActions } from "@atoms";
import { msgs } from "@config/conf";
import {
  filterOptions,
  formatCurrency,
  formatToPercentage,
  splitStringyCurrency,
  validateMaxValue,
  sanitizeValue,
  splitStringyRate,
  validateNumberField,
} from "@containers/components/helpers";
import useApiFetch from "@hooks/useApiFetch";
import { sanitizeNumber } from "@lib/number";

const { fieldError } = msgs;

const ProductsForm = (props) => {
  const { products, redirectUrl } = props;
  const { control, handleSubmit, reset, watch } = useForm({
    mode: "onChange",
  });
  const { id: paramsProductId } = useParams();
  const { get, put, post, isPending } = useApiFetch();

  const [carriersData, setCarriersData] = useState([]);
  const [productType, setProductType] = useState([]);
  const { maxPolicyIssueAge } = watch();

  const productTypeOptions = useMemo(
    () => filterOptions(productType, "productTypeID", "productTypeName"),
    [productType?.length]
  );

  const carriersOptions = useMemo(
    () => filterOptions(carriersData, "carrierID", "carrierName"),
    [carriersData?.length]
  );

  const getCarriers = useCallback(async () => {
    const data = await get("carriers/get");
    setCarriersData(data?.data);
  }, []);

  const getProductType = useCallback(async () => {
    const data = await get("products/productTypes");
    setProductType(data);
  }, []);

  useEffect(() => {
    getCarriers();
    getProductType();
    if (products) {
      const annuityFees = products?.annuityFees * 100;
      const annualBonus = products?.annualBonus * 100;
      const incomeAcceleratedCredit = products?.incomeAcceleratedCredit * 100;
      reset({
        ...products,
        annuityFees: formatToPercentage(annuityFees),
        annualBonus: formatToPercentage(annualBonus),
        incomeAcceleratedCredit: formatToPercentage(incomeAcceleratedCredit),
        minInitialPurchase: formatCurrency(products?.minInitialPurchase, 0),
      });
    }
  }, [products]);

  const onSavePayload = (formValues) => {
    let payloadValues;
    const {
      productTypeID,
      minInitialPurchase,
      minPolicyIssueAge,
      maxPolicyIssueAge,
      annuityFees,
      annualBonus,
      incomeAcceleratedCredit,
      productNotes,
      bankName,
      routingNumber,
      accountNumber,
    } = formValues || {};

    const commonValues = {
      ...formValues,
      annuityFees: splitStringyRate(annuityFees),
      annualBonus: splitStringyRate(annualBonus),
      incomeAcceleratedCredit: splitStringyRate(incomeAcceleratedCredit),
      minInitialPurchase: Number(splitStringyCurrency(minInitialPurchase)),
      minPolicyIssueAge: Number(splitStringyCurrency(minPolicyIssueAge)),
      maxPolicyIssueAge: Number(splitStringyCurrency(maxPolicyIssueAge)),
      notes: sanitizeValue(productNotes),
    };

    if (paramsProductId) {
      const { productBankName, productRoutingNumber, productAccountNumber } =
        formValues || {};
      payloadValues = {
        ...commonValues,
        bankName: sanitizeValue(productBankName),
        routingNumber: sanitizeValue(productRoutingNumber),
        accountNumber: sanitizeValue(productAccountNumber),
      };
    } else {
      payloadValues = {
        ...commonValues,
        bankName: sanitizeValue(bankName),
        routingNumber: sanitizeValue(routingNumber),
        accountNumber: sanitizeValue(accountNumber),
        procductTypeID: productTypeID,
      };
    }
    paramsProductId
      ? put(`products/${paramsProductId}`, payloadValues, { redirectUrl })
      : post("products/create", payloadValues, { redirectUrl });
  };

  const handleMinPolicyValidation = useCallback(
    (value) => {
      const minAge = Number(value);
      if (minAge >= maxPolicyIssueAge) {
        return "Must be less than maximum policy age.";
      }
      if (!minAge) {
        return fieldError;
      }
      return true;
    },
    [maxPolicyIssueAge]
  );

  return (
    <Card className="container_width">
      <CardBody className="form form--horizontal form_spacing">
        <Select
          name="carrierID"
          control={control}
          label="Carrier"
          placeholder="Select Carrier"
          rules={{ required: fieldError }}
          required
          options={carriersOptions}
          isLoading={isPending}
        />
        <Input
          name="productName"
          control={control}
          label="Product Name"
          rules={{ required: fieldError }}
          maxLength={200}
          required
        />
        <Select
          name="productTypeID"
          control={control}
          label="Product Type"
          placeholder="Select Product Type"
          rules={{ required: fieldError }}
          required
          options={productTypeOptions}
          isLoading={isPending}
        />
        <Input
          name="carrierProductCusip"
          control={control}
          label="Product Cusip"
          rules={{ required: fieldError }}
          maxLength={20}
          required
        />
        <Input
          name="carrierProductCode"
          control={control}
          label="Product Code"
          rules={{ required: fieldError }}
          maxLength={100}
          required
        />
        <Input
          name="productIdentifier"
          control={control}
          label="Product Identifier"
          rules={{ required: fieldError }}
          maxLength={100}
          required
        />
        <Input
          name="minInitialPurchase"
          control={control}
          label="Minimum Initial Purchase"
          rules={{
            required: fieldError,
            validate: (value) =>
              validateNumberField(sanitizeNumber(value), fieldError),
          }}
          formatter={{ numericOnly: true, shouldAmount: true }}
          maxLength={20}
          required
        />
        <Input
          name="minPolicyIssueAge"
          control={control}
          label="Minimum Policy Age"
          rules={{
            required: fieldError,
            validate: (value) => handleMinPolicyValidation(value),
          }}
          formatter={{ numericOnly: true }}
          maxLength={5}
          required
        />
        <Input
          name="maxPolicyIssueAge"
          control={control}
          label="Maximum Policy Age"
          rules={{
            required: fieldError,
            validate: (value) => validateNumberField(value, fieldError),
          }}
          formatter={{ numericOnly: true }}
          maxLength={5}
          required
        />
        <Input
          name="annuityFees"
          control={control}
          label="Product Fee"
          rules={{
            required: fieldError,
            validate: (value) => validateMaxValue(value),
          }}
          formatter={{ numericOnly: true, shouldPercentage: true }}
          maxLength={10}
          required
        />
        <Input
          name="annualBonus"
          control={control}
          label="Annual Bonus"
          rules={{
            required: fieldError,
            validate: (value) => validateMaxValue(value),
          }}
          formatter={{ numericOnly: true, shouldPercentage: true }}
          maxLength={10}
          required
        />
        <Input
          name="incomeAcceleratedCredit"
          control={control}
          label="Income Accelerated Credit"
          rules={{
            required: fieldError,
            validate: (value) => validateMaxValue(value, 1000),
          }}
          formatter={{ numericOnly: true, shouldPercentage: true }}
          maxLength={10}
          required
        />
        <Input
          name="productNotes"
          control={control}
          label="Notes"
          type="textarea"
          maxLength={5000}
        />
        <FormFooterActions
          linkProps={[{ path: redirectUrl }]}
          buttonProps={[{ onClick: handleSubmit(onSavePayload) }]}
        />
      </CardBody>
    </Card>
  );
};

export default ProductsForm;
