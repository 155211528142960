import { useCallback, useEffect, useState } from "react";

import jwt_decode from "jwt-decode";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import KeyVariantIcon from "mdi-react/KeyVariantIcon";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";

import { Input, PasswordInput } from "@atoms";
import { msgs } from "@config/conf";
import { useAuth } from "@contexts/AuthContext";
import useApiFetch from "@hooks/useApiFetch";

const LogInForm = () => {
  const { post, isPending } = useApiFetch();
  const { control, handleSubmit, reset } = useForm();
  const { setToken, setPayload } = useAuth();
  const history = useHistory();
  const [loginRes, setLoginRes] = useState("");

  const { loginName } = localStorage || {};
  const credentials = {
    loginName,
    password: "",
    remember: true,
  };

  const handleLogin = useCallback(async (values) => {
    localStorage.setItem("loginName", values.loginName);
    const response = await post("login", values, {
      loginAuth: true,
      successToast: false,
    });
    setLoginRes(response?.data?.token);
  }, []);

  useEffect(() => {
    reset(credentials);
    if (loginRes) {
      localStorage.setItem("authToken", loginRes);
      setToken(loginRes);
      setPayload(jwt_decode(loginRes));
      history.push("/workflows");
    }
  }, [loginRes]);

  return (
    <div className="form form--horizontal login-form form_spacing">
      <Input
        control={control}
        name="loginName"
        label="Login Name"
        rules={{ required: msgs.fieldError }}
        startIcon={<AccountOutlineIcon />}
      />
      <PasswordInput
        control={control}
        name="password"
        label="Password"
        type="password"
        wrapperClassName="mt-2"
        rules={{ required: msgs.fieldError }}
        startIcon={<KeyVariantIcon />}
      />
      <div className="w-100 flex justify-content-center">
        <Button
          onClick={handleSubmit(handleLogin)}
          color="primary"
          className="mt-4"
          disabled={isPending}
        >
          {isPending ? (
            <span className="spinner-border spinner-border-sm" />
          ) : (
            "Sign In"
          )}
        </Button>
      </div>
    </div>
  );
};

export default LogInForm;
