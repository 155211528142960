import { useEffect, useMemo, useRef, useState } from "react";

import { Card, CardBody } from "reactstrap";

import {
  FormFooterActions,
  Input,
  Modal,
  Select,
  DateRangePicker,
} from "@atoms";
import { msgs } from "@config/conf";
import { validateMaxValue } from "@containers/components/helpers";
import useQueryParams from "@hooks/useQueryParams";
import useToast from "@hooks/useToast";
import { getBusinessDay } from "@lib/date";

import {
  withdrawalMethodOptions,
  withdrawalTypeOptions,
  workflowTypeOptions,
} from "./transactionWorkflowHelpers";

const withdrawalEffectiveDate = getBusinessDay();

const queryParams = [
  "from",
  "search",
  "pageSize",
  "sortAccountNumber",
  "sortParticipantName",
  "sortPolicyNumber",
];

const nonZeroValue = "Amount cannot be Zero.";
const { fieldError } = msgs || {};

const NewTransactionWorkflowForm = ({
  form,
  accountId,
  onSubmit,
  policyNumber,
  policyStatus,
}) => {
  const { control, watch, handleSubmit, setValue } = form || {};

  const formRef = useRef();
  const { showErrorToast } = useToast();
  const [confirmModal, setConfirmModal] = useState(false);
  const { getQueryParams, buildQueryParams } = useQueryParams();

  const queryPathUrl = getQueryParams(queryParams);

  const {
    withdrawalAmount,
    withdrawalPercent,
    workflowType,
    withdrawalType,
    amount,
  } = watch();

  const isWithdrawal = workflowType === "Withdrawal";

  useEffect(() => {
    if (withdrawalAmount > 0) {
      setValue("withdrawalPercent", "0.00", { shouldValidate: true });
    }
  }, [withdrawalAmount]);

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (withdrawalType === "Termination") {
      setValue("withdrawalPercent", "100.00", { shouldValidate: true });
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [withdrawalType]);

  useEffect(() => {
    if (withdrawalPercent > 0) {
      setValue("withdrawalAmount", "0.00", { shouldValidate: true });
    }
  }, [withdrawalPercent]);

  const handleSave = () => {
    if (isWithdrawal) {
      if (!(Number(withdrawalPercent) || Number(withdrawalAmount))) {
        showErrorToast(
          "Withdrawal amount or Withdrawal Percentage cannot be Zero."
        );
        return;
      }
      if (!policyNumber || policyStatus !== "Active") {
        showErrorToast("RE Account does not have a policy number assigned");
        return;
      }
    } else if (!Number(amount)) {
      showErrorToast(nonZeroValue);
      return;
    }
    toggleModal();
  };

  const toggleModal = () => {
    setConfirmModal(!confirmModal);
  };

  const handleFormSubmission = () => {
    onSubmit();
    toggleModal();
    formRef.current?.click();
  };

  const goBackUrl = useMemo(() => {
    const queryParams = buildQueryParams(queryPathUrl);
    return `/accounts/${accountId}/edit?${queryParams}`;
  }, [accountId, queryPathUrl]);

  const commonProps = {
    control,
    required: true,
    rules: { required: fieldError },
  };

  const modalButtons = useMemo(
    () => [
      {
        name: "Cancel",
        color: "danger",
        onClick: () => setConfirmModal(!confirmModal),
      },
      {
        name: "Confirm",
        color: "primary",
        onClick: handleSubmit(handleFormSubmission),
      },
    ],
    [confirmModal, onSubmit]
  );

  return (
    <Card className="container_width">
      <Modal
        isModalOpen={confirmModal}
        setIsModalOpen={setConfirmModal}
        content={`Create new ${
          isWithdrawal ? "withdrawal" : "premium purchase"
        }?`}
        modalButtons={modalButtons}
      />
      <CardBody className="form form--horizontal product_page_wrapper">
        <Select
          name="workflowType"
          label="Workflow Type"
          options={workflowTypeOptions}
          {...commonProps}
        />
        {isWithdrawal ? (
          <>
            <Select
              name="withdrawalType"
              label="Withdrawal Type"
              options={withdrawalTypeOptions}
              {...commonProps}
            />
            <Select
              name="withdrawalMethod"
              label="Withdrawal Method"
              options={withdrawalMethodOptions}
              {...commonProps}
            />
            <Input
              control={control}
              name="withdrawalAmount"
              label="Withdrawal Amount"
              maxLength={12}
              formatter={{ numericOnly: true }}
              required
              isReadOnly={disabled}
            />
            <Input
              name="withdrawalPercent"
              control={control}
              label="Withdrawal Percent"
              required
              rules={{
                required: fieldError,
                validate: (value) => validateMaxValue(value),
              }}
              maxLength={6}
              formatter={{ numericOnly: true }}
              isReadOnly={disabled}
            />
            <DateRangePicker
              control={control}
              name="withdrawalEffDate"
              label="Effective Date"
              rules={{ required: fieldError }}
              defaultValue={withdrawalEffectiveDate}
            />
          </>
        ) : (
          <Input
            control={control}
            name="amount"
            label="Amount"
            maxLength={12}
            formatter={{ numericOnly: true }}
            required
          />
        )}
        <FormFooterActions
          linkProps={[{ path: goBackUrl }]}
          buttonProps={[{ onClick: handleSave }]}
        />
      </CardBody>
    </Card>
  );
};

export default NewTransactionWorkflowForm;
