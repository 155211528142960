import { useCallback, useEffect, useState } from "react";

import { Container } from "reactstrap";

import FormSpinner from "@containers/FormSpinner/FormSpinner";
import useApiFetch from "@hooks/useApiFetch";

import WorkflowTypeIdForm from "./WorkflowTypeIdForm";

const additionalFormFields = [
  { label: "Address Type", name: "addressType" },
  { label: "Address 1", name: "address1" },
  { label: "Address 2", name: "address2" },
  { label: "City", name: "city" },
  { label: "State", name: "stateCode" },
  { label: "Zip", name: "zip" },
  { label: "Country", name: "countryCode" },
];

const WorkflowTypeID1003 = ({
  match: {
    params: { workflowId },
  },
}) => {
  const { get, isPending } = useApiFetch();

  const [fetchData, setFetchData] = useState({});

  const getData = useCallback(async () => {
    const data = await get(`workflows/workflowdetail1003/${workflowId}`);
    setFetchData(data);
  }, [workflowId]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Participant Address Update</h3>

      <FormSpinner requesting={isPending} />
      <WorkflowTypeIdForm
        id={workflowId}
        data={fetchData}
        additionalFormFields={additionalFormFields}
      />
    </Container>
  );
};

export default WorkflowTypeID1003;
