import { Fragment, useCallback } from "react";

import { Link } from "react-router-dom";
import { Container, Button } from "reactstrap";

import { ExportToCsv } from "@atoms";

const HeaderActions = ({
  setRefresh,
  navigateLabel = "Add",
  navigatePath = "",
  additionalChild,
  exportCsvProps,
}) => {
  const { fileName, csvClickRef, csvData, getCsvExport, loadingResponse } =
    exportCsvProps || {};

  const renderRefreshButton = useCallback(() => {
    const isRefreshFunctionValid = typeof setRefresh === "function";
    return isRefreshFunctionValid ? (
      <Button key="refresh" color="success" onClick={() => setRefresh(true)}>
        Refresh
      </Button>
    ) : null;
  }, []);

  const renderNavigationLink = () => {
    const isNavigationPathValid = navigateLabel && navigatePath;
    return isNavigationPathValid ? (
      <Link key="navigate" to={navigatePath} className="btn btn-primary">
        {navigateLabel}
      </Link>
    ) : null;
  };

  const renderCsvExport = () => {
    const isCsvExportFunctionValid = typeof getCsvExport === "function";
    return isCsvExportFunctionValid ? (
      <ExportToCsv
        key="csvExport"
        csvData={csvData}
        fileName={fileName}
        getCsvExport={getCsvExport}
        loadingResponse={loadingResponse}
        csvRef={csvClickRef}
      />
    ) : null;
  };

  const actions = [
    { id: "refresh", element: renderRefreshButton() },
    { id: "navigate", element: renderNavigationLink() },
    { id: "export", element: renderCsvExport() },
    { id: "additional", element: additionalChild || null },
  ];

  return (
    <div>
      {actions
        .filter((action) => action.element)
        .map(({ element, id }) => (
          <Fragment key={id}>{element}</Fragment>
        ))}
    </div>
  );
};

const TableDashboard = ({
  pageTitle = "",
  subtitle = "",
  className = "",
  children,
  ...props
}) => (
  <Container>
    <div
      className={`flex justify-content-between align-items-center ${className}`}
    >
      <div>
        <h3 className="mb-2 text-green">{pageTitle}</h3>
        {subtitle && <h5 className="my-3 font-italic">{subtitle}</h5>}
      </div>
      <HeaderActions {...props} />
    </div>
    {children}
  </Container>
);

export default TableDashboard;
