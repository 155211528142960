import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { Card, CardBody } from "reactstrap";

import { Input, Modal, FormFooterActions } from "@atoms";
import { msgs } from "@config/conf";
import { sanitizeValue } from "@containers/components/helpers";
import useApiFetch from "@hooks/useApiFetch";
import useToast from "@hooks/useToast";
import { emailPattern } from "@shared/helpers/index";

const custodianFormFields = [
  {
    label: "Custodian Name",
    name: "custodianName",
    isRequired: true,
    rules: { required: msgs.fieldError },
  },
  {
    label: "Custodian Code",
    name: "custodianCode",
    isRequired: true,
    rules: { required: msgs.fieldError },
  },
  { label: "Contact Name", name: "contactName" },
  { label: "Contact Phone", name: "contactPhone" },
  {
    label: "Contact Email",
    name: "contactEmail",
    rules: { pattern: { value: emailPattern, message: "Email is invalid." } },
  },
  { label: "Bank Name", name: "bankName" },
  { label: "Routing Number", name: "routingNumber" },
  { label: "Account Number", name: "accountNumber" },
];

const CustodiansForm = ({ data, redirectUrl }) => {
  const {
    control,
    reset,
    handleSubmit,
    formState: { dirtyFields },
  } = useForm();
  const { validateRequiredField } = useToast();
  const { put, post } = useApiFetch();

  const [openModal, setOpenModal] = useState(false);

  const modalToggle = () => setOpenModal(!openModal);

  useEffect(() => {
    reset(data);
  }, [data]);

  const onSubmit = (values) => {
    Object.keys(values).forEach((key) => {
      if (typeof values[key] === "string") {
        values[key] = sanitizeValue(values[key]);
      }
    });

    const { custodianName, custodianCode } = values || {};

    const isValid = validateRequiredField({
      "Custodian Name": custodianName,
      "Custodian Code": custodianCode,
    });

    if (!isValid) {
      return;
    }

    data?.custodianID
      ? put(`custodians/${data?.custodianID}`, values, { redirectUrl })
      : post("custodians/create", values, { redirectUrl });

    // dispatch(updateCustodian(token, id, values));
  };

  const handleOnSave = () => {
    if (data?.custodianID && dirtyFields?.custodianCode) {
      handleSubmit(modalToggle)();
    } else {
      handleSubmit(onSubmit)();
    }
  };

  const onConformModal = () => {
    modalToggle();
    handleSubmit(onSubmit)();
  };

  const modalButtons = [
    {
      name: "Cancel",
      color: "danger",
      onClick: modalToggle,
    },
    {
      name: "Confirm",
      color: "primary",
      onClick: onConformModal,
    },
  ];

  return (
    <>
      <Modal
        isModalOpen={openModal}
        setIsModalOpen={setOpenModal}
        content="Changing the Custodian Code can disrupt connectivity with the
          Custodian. Please confirm you wish to make this change."
        title="Attention"
        variant="danger"
        iconName="lnr-flag"
        modalButtons={modalButtons}
      />
      <Card className="container_width">
        <CardBody className="form form--horizontal form_spacing">
          {custodianFormFields?.map(({ name, label, isRequired, rules }) => (
            <Input
              key={name}
              control={control}
              name={name}
              label={label}
              required={isRequired}
              rules={rules}
            />
          ))}
          <FormFooterActions
            linkProps={[{ path: redirectUrl }]}
            buttonProps={[{ onClick: handleOnSave }]}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default CustodiansForm;
