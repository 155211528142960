import { useCallback, useEffect, useMemo, useState } from "react";

import { useForm } from "react-hook-form";
import { Card, CardBody } from "reactstrap";

import { Input, Select, FormFooterActions } from "@atoms";
import { msgs, statusOptions } from "@config/conf";
import { filterOptions, sanitizeValue } from "@containers/components/helpers";
import useApiFetch from "@hooks/useApiFetch";
import useToast from "@hooks/useToast";
import { emailPattern } from "@shared/helpers/index";

const RecordKeepersForm = ({ data, redirectUrl }) => {
  const { control, reset, handleSubmit } = useForm();
  const { validateRequiredField } = useToast();
  const { get, post, put } = useApiFetch();

  const [recordKeeperChannels, setRecordKeeperChannels] = useState([]);

  const getData = useCallback(async () => {
    const fetchData = await get("rkchannels/get");
    if (fetchData?.data?.length) {
      setRecordKeeperChannels(fetchData?.data);
    }
  }, []);

  useEffect(() => {
    getData();
    reset(data);
  }, [data, getData, reset]);

  const commonProps = {
    control,
    required: true,
    rules: { required: msgs.fieldError },
  };

  const rkChannelOptions = useMemo(
    () => filterOptions(recordKeeperChannels, "userID", "userName"),
    [recordKeeperChannels]
  );

  const onSubmit = (values) => {
    Object.keys(values).forEach((key) => {
      if (typeof values[key] === "string") {
        values[key] = sanitizeValue(values[key]);
      }
    });

    const { recordkeeperName, extRecordkeeperIdentifier } = values || {};

    const isValid = validateRequiredField({
      "RecordKeeper Name": recordkeeperName,
      "External RecordKeeper Identifier": extRecordkeeperIdentifier,
    });

    if (!isValid) {
      return;
    }

    data?.recordkeeperID
      ? put(`recordkeepers/${data?.recordkeeperID}`, values, { redirectUrl })
      : post("recordkeepers/create", values, { redirectUrl });
  };

  return (
    <Card className="container_width">
      <CardBody className="form form--horizontal form_spacing">
        <Select
          {...commonProps}
          name="rkChannelUserID"
          label="RK Channel"
          placeholder="Select a channel"
          options={rkChannelOptions}
        />
        <Input
          {...commonProps}
          name="recordkeeperName"
          label="Recordkeeper Name"
          maxLength="200"
        />
        <Input
          {...commonProps}
          name="extRecordkeeperIdentifier"
          label="External Recordkeeper Identifier"
          maxLength="200"
        />
        <Input
          control={control}
          name="contactName"
          label="Contact Name"
          maxLength="100"
        />
        <Input
          control={control}
          name="contactPhone"
          label="Contact Phone"
          maxLength="50"
        />
        <Input
          control={control}
          name="contactEmail"
          label="Contact Email"
          maxLength="100"
          rules={{
            pattern: { value: emailPattern, message: "Email is invalid." },
          }}
        />
        <Select
          {...commonProps}
          name="status"
          label="Status"
          options={statusOptions}
        />
        <FormFooterActions
          linkProps={[{ path: redirectUrl }]}
          buttonProps={[{ onClick: handleSubmit(onSubmit) }]}
        />
      </CardBody>
    </Card>
  );
};

export default RecordKeepersForm;
