import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

import { Input } from "@atoms";
import useQueryParams from "@hooks/useQueryParams";

const workflow1005Fields = [
  { name: "workflowNumber", label: "Workflow Number" },
  { name: "workflowType", label: "Workflow Type" },
  { name: "totalSuccessfull", label: "Total Successfull" },
  { name: "totalUnSuccessfull", label: "Total Unsuccessfull" },
  { name: "totalProcessed", label: "Total Processed" },
];

const WorkflowTypeID1005Form = ({ workflowId }) => {
  const { control } = useForm();
  const { getQueryParams } = useQueryParams();
  const page = getQueryParams("from");

  return (
    <Card className="container_width">
      <CardBody className="form form--horizontal form_spacing">
        {workflow1005Fields?.map(({ label, name }) => (
          <Input
            control={control}
            key={name}
            label={label}
            name={name}
            isReadOnly
          />
        ))}
        <div className="form__form-btns w-100 flex justify-content-between">
          <Link
            to={`/workflows?page=${page}`}
            className="btn btn-outline-danger"
          >
            Cancel
          </Link>
          <Link
            to={`/workflows/workflowdetail1005/${workflowId}/processing-log?from=${page}`}
            className="btn btn-primary"
          >
            Log
          </Link>
        </div>
      </CardBody>
    </Card>
  );
};

export default WorkflowTypeID1005Form;
