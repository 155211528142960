import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(timezone);
dayjs.extend(utc);

const checkValidDate = (date) => dayjs(date).isValid();
export const currentDate = dayjs();

export const formatDate = (date, format = "MM/DD/YYYY") => {
  if (!date) return "";
  const isValidDate = checkValidDate(date);
  return isValidDate ? dayjs(date).local().format(format) : "";
};

export const formatDateTime = (date, type = "MM/DD/YYYY hh:mm A") =>
  date ? dayjs(date).local().format(type) : "";

export const easternTimeZone = new Date().toLocaleDateString("en-US", {
  timeZone: "US/Eastern",
});

export const getRelativeDate = (
  days,
  unit,
  direction,
  format = "MM/DD/YYYY"
) => {
  if (direction === "previous") {
    return currentDate.subtract(days, unit).format(format);
  }
  if (direction === "next") {
    return currentDate.add(days, unit).format(format);
  }
  return "";
};

export const calculateAge = (dob) => {
  const isValidDate = checkValidDate(dob);
  const birthdate = dayjs(dob);
  return isValidDate ? currentDate.diff(birthdate, "years") : "";
};

export const parsedDate = (date) => {
  const isValidDate = checkValidDate(date);
  return isValidDate ? dayjs(date) : "";
};

export const getBusinessDay = (date) => {
  if (date) {
    return formatDate(date);
  }
  const dayOfWeek = currentDate.day();
  const adjustedDate =
    dayOfWeek === 0
      ? currentDate.subtract(2, "days")
      : dayOfWeek === 6
        ? currentDate.subtract(1, "days")
        : currentDate.subtract(1, "days");

  return formatDate(adjustedDate);
};
