import { useCallback, useEffect } from "react";

import { useForm } from "react-hook-form";
import { Card, CardBody } from "reactstrap";

import { Input, Table, FormFooterActions } from "@atoms";
import {
  displayNumberFormat,
  formatFixedDigits,
  formatToPercentage,
} from "@containers/components/helpers";
import useQueryParams from "@hooks/useQueryParams";
import { formatDate } from "@lib/date";

const queryPath = [
  "page",
  "searchInput",
  "wfType",
  "statusInput",
  "fromDate",
  "toDate",
  "sortWFNumber",
  "sortWType",
  "sortWDate",
  "sortReAccountNumber",
  "pageSize",
];

const workflowFormFields = [
  { label: "Workflow Number", name: "workflowNumber" },
  { label: "Workflow Type", name: "workflowType" },
  { label: "RE Account #", name: "reAccountNumber" },
  { label: "Policy #", name: "policyNumber" },
  { label: "Participant", name: "participantName" },
  { label: "SSN", name: "ssNumber" },
];

const piosColumns = [
  {
    id: "pioCode",
    header: "PIO Code",
    accessorKey: "carrierPIOCode",
    enableSorting: false,
    size: "50px",
  },
  {
    id: "pioName",
    header: "PIO Name",
    accessorKey: "pioName",
    enableSorting: false,
    size: "250px",
  },
  {
    id: "pioAllocation",
    header: "Pio Allocation",
    enableSorting: false,
    size: "100px",
    cell: (tableRow) => {
      const { allocationRate } = tableRow?.row?.original || "";
      return (
        <div>{allocationRate ? formatToPercentage(allocationRate, 0) : ""}</div>
      );
    },
  },
];

let formFields = workflowFormFields;

const WorkflowTypeIdForm = ({ id, data, additionalFormFields, hasTable }) => {
  const { control, reset } = useForm();
  const { getQueryParams, buildQueryParams } = useQueryParams();

  const { page, ...restParams } = getQueryParams(queryPath);

  useEffect(() => {
    const {
      workflowNumber,
      reAccountNumber,
      dob,
      activityDate,
      activityAmount,
      terminatePolicy,
      dateSettled,
      withdrawalAmount,
      withdrawalPercent,
      netAmount,
      feeAmount,
      mvaAmount,
    } = data || {};
    reset({
      ...data,
      workflowNumber: workflowNumber
        ? formatFixedDigits(12, workflowNumber)
        : "",
      reAccountNumber: reAccountNumber || "",
      dob: formatDate(dob, "YYYY-MM-DD"),
      activityDate: formatDate(activityDate),
      activityAmount: displayNumberFormat(activityAmount, 2) || "",
      terminatePolicy: terminatePolicy ? "Yes" : "No",
      dateSettled: formatDate(dateSettled, "YYYY-MM-DD"),
      withdrawalAmount: displayNumberFormat(withdrawalAmount, 2) || "",
      withdrawalPercent: displayNumberFormat(withdrawalPercent, 2) || "",
      netAmount: displayNumberFormat(netAmount, 2) || "",
      feeAmount: displayNumberFormat(feeAmount, 2) || "",
      mvaAmount: displayNumberFormat(mvaAmount, 2) || "",
    });
  }, [data]);

  useEffect(() => {
    if (additionalFormFields?.length) {
      formFields = workflowFormFields.concat(additionalFormFields);
    }
  }, [additionalFormFields?.length]);

  const renderButton = useCallback(() => {
    const queryParams = buildQueryParams({ from: page, ...restParams });

    return (
      <FormFooterActions
        linkProps={[{ path: `/workflows/${id}/edit?${queryParams}` }]}
      />
    );
  }, [id, page, restParams]);

  return (
    <div>
      <Card className="container_width">
        <CardBody className="form form--horizontal form_spacing">
          {formFields?.map(({ label, name }) => (
            <Input
              control={control}
              key={name}
              name={name}
              label={label}
              type="text"
              isReadOnly
            />
          ))}
          {!hasTable ? renderButton() : null}
        </CardBody>
      </Card>

      {hasTable ? (
        <Card>
          <CardBody>
            <Table
              data={data?.workflowsecond || []}
              columns={piosColumns}
              hidePagination
              noPadding
            />
            {renderButton()}
          </CardBody>
        </Card>
      ) : null}
    </div>
  );
};

export default WorkflowTypeIdForm;
