export const baseUrl =
  "https://app-api-recore-prod-centralus.azurewebsites.net/api/";
export const app = {
  name: "Welcome to Retirement Edge",
  environment: "development",
};

export const msgs = {
  success: "Operation successful.",
  error: "Operation failed.",
  noRows: "No records to display.",
  authError: "Your session has expired. Please log in again.",
  fieldError: "This field is required.",
  annuityAllocationAuthError:
    "An error occurred while updating allocations or Session Expired",
};

export const conf = {
  // serverURL: "https://dev-api.retirement-edge.com/api/1/",
  serverURL: "https://app-api-recore-prod-centralus.azurewebsites.net/api/1/",
  idleTimeout: 10,
};

export const domainUrl = {
  local: "localhost:3001",
  dev: "dev-annuitant-ui.retirement-edge.com",
  stage: "stage-annuitant-ui.retirement-edge.com",
  production: "annuitant-ui.retirement-edge.com",
};

export const pagination = {
  length: 20,
  list: [20, 30, 40, 50],
};

export const statusOptions = [
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "Inactive" },
];

export const progressStatusOptions = [
  { label: "In Progress", value: "In Progress" },
  { label: "Completed", value: "Completed" },
  { label: "Failed", value: "Failed" },
  { label: "Error", value: "Error" },
];

export const dateRangeOptions = [
  { label: "All Entries", value: "AllEntries" },
  { label: "Current Month", value: "CurrentMonth" },
  { label: "Last Month", value: "LastMonth" },
  { label: "Current Quarter", value: "CurrentQuarter" },
  { label: "Last Quarter", value: "LastQuarter" },
  { label: "Custom Range", value: "CustomRange" },
  { label: "Today", value: "Today" },
];

export const CognitoFormAccountId = "9LE9gkNsKEqE0uCOyZZkrw";

export const RETIREMENT_AGE = 67;
export const GROWTH_RATE = 0.02;
export const INACTIVE_TIMEOUT = conf.idleTimeout * 60 * 1000;
