import { useForm } from "react-hook-form";
import { Card, CardBody } from "reactstrap";

import { Input, FormFooterActions } from "@atoms";
import { msgs } from "@config/conf";

const workflow1002ResubmitFormFields = [
  {
    label: "Workflow Number",
    name: "workflowNumber",
    readOnly: true,
  },
  {
    label: "Workflow Type",
    name: "workflowType",
    readOnly: true,
  },
  {
    label: "RE Account #",
    name: "reAccountNumber",
    readOnly: true,
  },
  {
    label: "Policy #",
    name: "policyNumber",
    readOnly: true,
  },
  {
    label: "Participant",
    name: "participantName",
    readOnly: true,
  },

  {
    label: "Carrier",
    name: "carrier",
    readOnly: true,
  },
  {
    label: "Product",
    name: "product",
    readOnly: true,
  },
  {
    label: "Plan",
    name: "plan",
    readOnly: true,
  },
  {
    label: "Social Security #",
    name: "ssNumber",
    maxLength: 9,
  },
  {
    label: "Date of Birth",
    name: "dateOfBirth",
    isRequired: true,
    maxLength: 10,
  },
];

const ResubmitWFT1002Form = () => {
  const { control } = useForm();

  const formFooterProps = {
    linkProps: [
      { labelName: "Cancel", path: `/workflows/${"id"}/edit?from=${"page"}` },
    ],
    buttonProps: [{ onClick: () => {} }],
  };

  return (
    <Card className="container_width">
      <CardBody className="form form--horizontal form_spacing">
        {workflow1002ResubmitFormFields?.map(
          ({ label, name, isRequired, maxLength, readOnly }) => {
            const requiredProps = isRequired
              ? {
                  control,
                  required: true,
                  rules: { required: msgs.fieldError },
                }
              : { control };
            return (
              <Input
                {...requiredProps}
                label={label}
                name={name}
                key={name}
                maxLength={maxLength}
                isReadOnly={readOnly}
              />
            );
          }
        )}
        <FormFooterActions {...formFooterProps} />
      </CardBody>
    </Card>
  );
};

export default ResubmitWFT1002Form;
