import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { Badge, UncontrolledTooltip } from "reactstrap";

import { Spinner, Table } from "@atoms";
import { pagination } from "@config/conf";
import { formatCurrency, getPagination } from "@containers/components/helpers";
import useApiFetch from "@hooks/useApiFetch";
import useQueryParams from "@hooks/useQueryParams";
import { formatDate } from "@lib/date";

import CashLedgerTableSearch from "./CashLedgerTableSearch";

const queryParams = [
  "effectiveStartDate",
  "effectiveEndDate",
  "dateRangeInput",
  "page",
  "pageSize",
];

const Currency = ({ value, id }) => (
  <div className="column-end">
    <span className={`${value < 0 ? "text-danger" : ""}`} id={id}>
      {formatCurrency(Math.abs(value), 2)}
    </span>
  </div>
);

const CashLedgerTable = ({ refresh, setRefresh, setPendingRecords }) => {
  const { get, isPending } = useApiFetch();
  const history = useHistory();
  const { getQueryParams, buildQueryParams } = useQueryParams();

  const {
    effectiveStartDate,
    effectiveEndDate,
    dateRangeInput: dateRangeRequested,
    page,
    pageSize: queryPageSize,
  } = getQueryParams(queryParams);

  const [submitSearch, setSubmitSearch] = useState(false);
  const [pageSize, setPageSize] = useState(queryPageSize || pagination.length);
  const [cashLedgerData, setCashLedgerData] = useState({});
  const { offset, pageNumber } = getPagination(pageSize, page);

  const form = useForm({
    defaultValues: {
      dateRangeInput: dateRangeRequested || "Today",
      startDate: effectiveStartDate,
      endDate: effectiveEndDate,
    },
  });

  const { getValues } = form;
  const values = getValues();
  const { dateRangeInput, startDate, endDate } = values;

  const {
    numPending,
    recordCount = 0,
    cashLedgerSummaryResponse = [],
  } = cashLedgerData || {};

  useEffect(() => {
    setPendingRecords(numPending);
  }, [numPending]);

  const redirectUrl = useCallback(
    (id = "") => {
      const query = buildQueryParams({
        dateRangeInput,
        effectiveStartDate: startDate,
        effectiveEndDate: endDate,
        pageSize,
      });
      return `/cash-ledger${
        id ? `/${id}/edit?from=${pageNumber}` : "?page=1"
      }&${query}`;
    },
    [dateRangeInput, startDate, endDate, pageNumber, pageSize]
  );

  const getCashLedgersData = useCallback(async () => {
    const params = buildQueryParams({
      Limit: pageSize,
      Offset: offset,
      dateRangeRequested: dateRangeInput,
      effectiveStartDate: startDate,
      effectiveEndDate: endDate,
    });
    const response = await get(`operations/cashledger/dailysummary?${params}`);
    setCashLedgerData(response?.dailysummary);
  }, [submitSearch, offset, pageSize]);

  useEffect(() => {
    if (submitSearch) {
      const pageReload = redirectUrl();
      history.push(pageReload);
    } else {
      getCashLedgersData();
    }
    setRefresh(false);
    setSubmitSearch(false);
  }, [getCashLedgersData, refresh, submitSearch]);

  const pageProps = useMemo(
    () => ({
      total: recordCount,
      pageSize,
      setPageSize,
      pageNumber,
    }),
    [recordCount, pageSize, pageNumber]
  );

  const cashLedgerColumn = useMemo(
    () => [
      {
        id: "effectiveDate",
        header: "Date",
        accessorFn: (row) => formatDate(row?.effectiveDate),
      },
      {
        id: "summaryCashIn",
        header: "Cash In",
        alignRight: true,
        cell: (tableRow) => {
          const { summaryCashIn } = tableRow.row.original || {};
          return <Currency value={summaryCashIn} />;
        },
      },
      {
        id: "summaryCashOut",
        header: "Cash Out",
        alignRight: true,
        cell: (tableRow) => {
          const { summaryCashOut } = tableRow.row.original || {};
          return <Currency value={summaryCashOut} />;
        },
      },
      {
        id: "summaryAmount",
        header: "Total",
        alignRight: true,
        cell: (tableRow) => {
          const { summaryAmount } = tableRow.row.original || {};
          return <Currency value={summaryAmount} />;
        },
      },
      {
        id: "adjustmentAmount",
        header: "Adjustment",
        alignRight: true,
        cell: (tableRow) => {
          const { adjustmentAmount, cashSummaryID, adjustmentNotes } =
            tableRow.row.original || {};
          return (
            <div>
              <Currency
                id={`tooltip_${cashSummaryID}`}
                value={adjustmentAmount}
              />
              {adjustmentNotes ? (
                <UncontrolledTooltip
                  placement="top"
                  target={`tooltip_${cashSummaryID}`}
                >
                  {adjustmentNotes}
                </UncontrolledTooltip>
              ) : null}
            </div>
          );
        },
      },
      {
        id: "beginningBalance",
        header: "Beginning",
        alignRight: true,
        cell: (tableRow) => {
          const { beginningBalance } = tableRow.row.original || {};
          return <Currency value={beginningBalance} />;
        },
      },
      {
        id: "endingBalance",
        header: "Ending",
        alignRight: true,
        cell: (tableRow) => {
          const { endingBalance } = tableRow.row.original || {};
          return <Currency value={endingBalance} />;
        },
      },
      {
        id: "Edit-View",
        header: "",
        size: 250,
        cell: (tableRow) => {
          const { cashSummaryID } = tableRow.row.original || {};
          const navigateToEditPage = redirectUrl(cashSummaryID);
          return (
            <div className="column-end pr-2 my-1">
              <Link to={navigateToEditPage}>
                <Badge className="btn-blue btn-badge align-middle">
                  View/Edit
                </Badge>
              </Link>
            </div>
          );
        },
      },
    ],
    [redirectUrl]
  );

  return (
    <Spinner requesting={isPending}>
      <CashLedgerTableSearch form={form} setSubmitSearch={setSubmitSearch} />
      <Table
        columns={cashLedgerColumn}
        data={cashLedgerSummaryResponse}
        pageProps={pageProps}
      />
    </Spinner>
  );
};

export default CashLedgerTable;
