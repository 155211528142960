import React from "react";

import { Route, Switch } from "react-router-dom";

// import Landing from '@containers/Landing/index';
import LogIn from "@containers/Account/LogIn";
import AnnuityDocumentVault from "@containers/Accounts/ViewAccount/components/AnnuityDocumentVault";
import UpdateAnnuityAllocationsWrapper from "@containers/Accounts/ViewAccount/components/UpdateAnnuityAllocationsWrapper";
import UserLoggedOut from "@containers/Accounts/ViewAccount/components/UserLoggedOut";
import ViewAccountSummary from "@containers/Accounts/ViewAccount/ViewAccountSummary";
import ViewGenericUserAccountSummary from "@containers/Accounts/ViewAccount/ViewGenericUserAccountSummary";
import NotFound404 from "@containers/DefaultPage/404";
import Environment from "@containers/Environment/Environment";

import WrappedRoutes from "./WrappedRoutes";
import MainWrapper from "../MainWrapper";

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/" component={LogIn} />
        <Route
          exact
          path="/View/Account/Summary/:id"
          component={ViewAccountSummary}
        />
        <Route
          exact
          path="/View/GenericUserAccount/Summary/:id"
          component={ViewGenericUserAccountSummary}
        />
        <Route
          exact
          path="/View/Account/Documents/"
          component={AnnuityDocumentVault}
        />
        <Route exact path="/ViewAccountLogOut" component={UserLoggedOut} />
        <Route path="/404" component={NotFound404} />
        <Route
          exact
          path="/View/Account/UpdateAnnuityAllocations/"
          component={UpdateAnnuityAllocationsWrapper}
        />
        {/* <Route path="/lock_screen" component={LockScreen} /> */}
        {/* <Route path="/log_in" component={LogIn} /> */}
        {/* <Route path="/log_in_photo" component={LogInPhoto} />
        <Route path="/register" component={Register} />
        <Route path="/register_photo" component={RegisterPhoto} />
        <Route path="/reset_password" component={ResetPassword} />
        <Route path="/reset_password_photo" component={ResetPasswordPhoto} /> */}
        <Route path="/environment" component={Environment} />
        <Route path="/" component={WrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
