import { useEffect } from "react";

import LogInForm from "./LogInForm";

const LogIn = () => {
  useEffect(() => {
    if (window.location.pathname === "/Profile".toLowerCase()) {
      window.location.reload();
    }
  }, []);

  return (
    <div className="account">
      <div className="account__card">
        <h3 className="mb-4 text-center">
          Welcome to
          <span className="font-weight-light"> Retirement Edge</span>
        </h3>
        <LogInForm />
      </div>
    </div>
  );
};

export default LogIn;
