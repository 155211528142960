import { useCallback, useEffect, useState } from "react";

import { Badge } from "reactstrap";

import { Table, Spinner } from "@atoms";
import { pagination } from "@config/conf";
import { getPagination } from "@containers/components/helpers";
import useApiFetch from "@hooks/useApiFetch";
import useQueryParams from "@hooks/useQueryParams";
import { formatDate, formatDateTime } from "@lib/date";

const queryPath = ["pageSize", "vaultPage"];

const DocumentVaultTable = ({ id }) => {
  const { getQueryParams } = useQueryParams();
  const { get } = useApiFetch();

  const { pageSize: pageSizeQuery, vaultPage } = getQueryParams(queryPath);
  const [pageSize, setPageSize] = useState(pageSizeQuery || pagination.length);
  const [accountVault, setAccountVault] = useState({});

  const { offset, pageNumber } = getPagination(pageSize, vaultPage);

  const handleDocumentView = async (docId) => {
    const data = await get(`reaccounts/documentvault/document/${docId}`);
    if (data?.data?.documentURL) {
      window.open(data?.data?.documentURL, "_blank");
    }
  };

  const { data: tableData = [], recordCount = 0 } = accountVault || {};

  const documentVaultColumns = [
    {
      id: "documentTitle",
      header: "Title",
      accessorKey: "documentTitle",
      enableSorting: false,
      size: "230px",
    },
    {
      id: "documentDescription",
      header: "Description",
      accessorKey: "documentDescription",
      enableSorting: false,
      size: "400px",
    },
    {
      id: "private",
      header: "Private",
      size: "100px",
      cell: (tableRow) => {
        const { Private } = tableRow?.row?.original || {};
        return Private ? "Yes" : "No";
      },
    },
    {
      id: "documentEffectiveDate",
      header: "Effective",
      cell: (tableRow) => {
        const { documentEffectiveDate } = tableRow?.row?.original || {};
        return formatDate(documentEffectiveDate);
      },
    },
    {
      id: "documentPostedDate",
      header: "Posted",
      cell: (tableRow) => {
        const { documentPostedDate } = tableRow?.row?.original || {};
        return formatDateTime(documentPostedDate);
      },
    },
    {
      id: "carrierReferenceID",
      header: "Carrier Ref",
      accessorKey: "carrierReferenceID",
      enableSorting: false,
      size: "200px",
    },
    {
      id: "view-button",
      header: " ",
      cell: (tableRow) => {
        const { documentID } = tableRow?.row?.original || {};
        return (
          <div className="text-right">
            <Badge
              className="btn-blue"
              style={{ cursor: "pointer" }}
              onClick={() => handleDocumentView(documentID)}
            >
              View
            </Badge>
          </div>
        );
      },
    },
  ];

  const getAccountVault = useCallback(async () => {
    const data = await get(
      `reaccounts/documentvault/${id}?Offset=${offset}&Limit=${pageSize}`
    );
    setAccountVault(data);
  }, [offset, pageSize]);

  useEffect(() => {
    getAccountVault();
  }, [getAccountVault]);

  const pageProps = {
    total: recordCount,
    pageSize,
    setPageSize,
    pageNumber,
    fromPage: pageNumber,
    pageName: "vaultPage",
  };

  return (
    <Spinner requesting={accountVault?.requesting}>
      <Table
        columns={documentVaultColumns}
        data={tableData}
        status={accountVault?.err}
        pageProps={pageProps}
        noPadding
        noBottomBorder
      />
    </Spinner>
  );
};

export default DocumentVaultTable;
