import { useState } from "react";

import axios from "axios";
import { useHistory } from "react-router-dom";

import { baseUrl, conf, msgs } from "@config/conf";
import { useAuth } from "@contexts/AuthContext";

import useToast from "./useToast";

const { authError, error, annuityAllocationAuthError } = msgs || {};

const useApiFetch = () => {
  const { token, logout, annuityToken, annuityLogout } = useAuth();

  const history = useHistory();
  const { showErrorToast, showSuccessToast } = useToast();
  const [isPending, setIsPending] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const path = window.location.pathname;
  const isViewAccountSummary = path.includes("/View/Account/Summary");
  const isViewGenericUserAccountSummary = path.includes(
    "/View/GenericUserAccount/Summary"
  );
  const isViewAccountDocuments = path.includes("View/Account/Documents");
  const isViewAccountUpdateAllocation = path.includes(
    "/View/Account/UpdateAnnuityAllocations"
  );

  const authToken = isViewGenericUserAccountSummary
    ? annuityToken
    : token || annuityToken;

  const handleUnauthorizedError = () => {
    if (
      isViewAccountSummary ||
      isViewAccountDocuments ||
      isViewGenericUserAccountSummary
    ) {
      sessionStorage.setItem("validToken", false);
      sessionStorage.removeItem("annuityAuthToken");
      annuityLogout();
    } else if (isViewAccountUpdateAllocation) {
      setErrorMessage(annuityAllocationAuthError);
    } else {
      logout();
      showErrorToast(authError);
      history.push("/");
    }
  };

  const request = async (method, url, values, options) => {
    const {
      redirectUrl,
      successToast = true,
      loginAuth = false,
    } = options || {};

    if (loginAuth ? true : authToken) {
      try {
        setIsPending(true);
        const response = await axios({
          method,
          url: `${conf.serverURL}${url}`,
          data: values,
          headers: {
            "Content-Type": "application/json",
            accessToken: authToken,
          },
        });
        if (response.status === 200) {
          if (successToast) {
            showSuccessToast();
          }
          if (redirectUrl) {
            history.push(redirectUrl);
          }
        }
        setIsPending(false);
        return response;
      } catch (err) {
        setIsPending(false);
        if (err?.response?.status === 401) {
          loginAuth
            ? showErrorToast(err?.response?.data?.message)
            : handleUnauthorizedError();
        } else {
          showErrorToast(err?.response?.data?.message || error);
        }
      }
    }
  };

  const post = (url, values, options) => request("post", url, values, options);
  const put = (url, values, options) => request("put", url, values, options);

  const get = async (url, options) => {
    const {
      errorToast = true,
      queryParams,
      shouldUseBaseUrl = false,
    } = options || {};
    const finalUrl = shouldUseBaseUrl ? baseUrl : conf.serverURL;

    if (authToken) {
      try {
        setIsPending(true);
        const response = await axios({
          method: "get",
          url: `${finalUrl}${url}`,
          params: queryParams,
          headers: {
            "Content-Type": "application/json",
            accessToken: authToken,
          },
        });
        if (response.status === 200) {
          setIsPending(false);
          return response?.data;
        }
      } catch (err) {
        setIsPending(false);
        setErrorMessage(err?.response?.data?.message || error);
        if (err?.response?.status === 401) {
          handleUnauthorizedError();
        } else if (errorToast) {
          showErrorToast(err?.response?.data?.message || error);
        }
      }
    }
  };

  return {
    post,
    get,
    put,
    isPending,
    errorMessage,
  };
};

export default useApiFetch;
