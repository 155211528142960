import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Container } from "reactstrap";

import {
  sanitizeValue,
  splitStringyRate,
} from "@containers/components/helpers";
import FormSpinner from "@containers/FormSpinner/FormSpinner";
import useApiFetch from "@hooks/useApiFetch";
import useQueryParams from "@hooks/useQueryParams";
import useToast from "@hooks/useToast";

import PIOForm from "../PIOForm/PIOForm";

const queryPath = ["from", "pageSize", "sortProductName", "sortCarrierName"];

const processRate = (rate) => {
  return rate === "NA" || rate === "" ? null : splitStringyRate(rate);
};

const EditPIO = (props) => {
  const {
    match: {
      params: { id, pioId },
    },
  } = props;

  const { get, put, isPending } = useApiFetch();
  const [productPio, setProductPio] = useState();

  const { getQueryParams, buildQueryParams } = useQueryParams();
  const { validateRequiredField } = useToast();

  const { from: page, ...restParams } = getQueryParams(queryPath);

  const getProductPio = useCallback(async () => {
    const response = await get(`products/pios/${pioId}`);
    setProductPio(response);
  }, [pioId]);

  useEffect(() => {
    getProductPio();
  }, [getProductPio]);

  const redirectToProducts = useMemo(() => {
    const queryParams = buildQueryParams({ id, page, ...restParams });

    return `/products?${queryParams}`;
  }, [id, page, restParams]);

  const handleForm = (values) => {
    Object.keys(values).forEach((key) => {
      if (typeof values[key] === "string") {
        values[key] = sanitizeValue(values[key]);
      }
    });

    const { pioid, pioLastUpdatedOn, piopkid, productName, ...rest } =
      values || {};

    const isValid = validateRequiredField({
      "Investment Option": rest?.pioName,
      "Investment Option Code": rest?.carrierPIOCode,
    });

    if (!isValid) {
      return;
    }

    const payloadValues = {
      ...rest,
      pioCreditingRate: processRate(rest?.pioCreditingRate),
      pioParticipationRate: processRate(rest?.pioParticipationRate),
      fiaCapRate: processRate(rest?.fiaCapRate),
      pioMinRate: processRate(rest?.pioMinRate),
      pioMaxRate: processRate(rest?.pioMaxRate),
    };

    put(`products/pios/${pioId}`, payloadValues, {
      redirectUrl: redirectToProducts,
    });
  };

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Product Investment Option Detail</h3>

      <FormSpinner requesting={isPending} />
      <PIOForm
        data={productPio}
        onSubmit={handleForm}
        redirectUrl={redirectToProducts}
      />
    </Container>
  );
};

export default EditPIO;
