import { useMemo, useEffect, useState, useCallback } from "react";

import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Badge, UncontrolledTooltip } from "reactstrap";

import { Spinner, Table } from "@atoms";
import { pagination } from "@config/conf";
import { getPagination } from "@containers/components/helpers";
import useApiFetch from "@hooks/useApiFetch";
import useQueryParams from "@hooks/useQueryParams";
import { formatDate } from "@lib/date";
import { formatFixedDigits, maskSSN } from "@lib/number";

import AccountsTableSearch from "./AccountsTableSearch";

const queryParams = [
  "page",
  "sortAccountNumber",
  "sortParticipantName",
  "sortPolicyNumber",
  "pageSize",
  "search",
];

const workflowTableColumns = [
  {
    id: "workflowNumber",
    header: "WF#",
    accessorFn: (row) => formatFixedDigits(row?.workflowNumber, 12),
  },
  {
    id: "workflowType",
    header: "Type",
    accessorKey: "workflowType",
  },
  {
    id: "workflowStartDate",
    header: "Start Date",
    accessorFn: (row) => formatDate(row.workflowStartDate, "MM/DD/YY hh:mm A"),
  },
  {
    id: "workflowEndDate",
    header: "End Date",
    accessorFn: (row) => formatDate(row.workflowEndDate, "MM/DD/YY hh:mm A"),
  },
  {
    id: "workflowStatus",
    header: "Status",
    accessorFn: (row) => `${row?.workflowStatus} / ${row?.workflowSubStatus}`,
  },
];

const AccountSubTable = (subTableprops) => {
  const { row } = subTableprops || {};
  const { workflowData = [] } = row?.original || {};
  return (
    <Table columns={workflowTableColumns} data={workflowData} hasNestedTable />
  );
};

const ToolTip = ({ accountId, label, data, targetId, placement = "top" }) => (
  <>
    <span id={targetId}>{data}</span>
    {accountId && (
      <UncontrolledTooltip placement={placement} target={targetId}>
        {label}
      </UncontrolledTooltip>
    )}
  </>
);

const AccountTable = (props) => {
  const { refresh, setRefresh, setExportSearch, setExportOrderBy } =
    props || {};

  const { get, isPending } = useApiFetch();
  const [tableData, setTableData] = useState();
  const history = useHistory();
  const { getQueryParams, buildQueryParams } = useQueryParams();

  const {
    page: pageQuery,
    sortAccountNumber,
    sortParticipantName,
    sortPolicyNumber,
    pageSize: queryPageSize,
    search,
  } = getQueryParams(queryParams);

  const form = useForm({
    defaultValues: {
      searchInput: search,
    },
  });

  const { getValues } = form;
  const { searchInput } = getValues();

  const [submitSearch, setSubmitSearch] = useState(false);
  const [pageSize, setPageSize] = useState(queryPageSize || pagination.length);
  const { offset, pageNumber } = getPagination(pageSize, pageQuery);
  const { recordCount = 0, data = [] } = tableData || {};

  const redirectUrl = useCallback(
    (accountId = "") => {
      const query = buildQueryParams({
        search: searchInput,
        sortAccountNumber,
        sortParticipantName,
        sortPolicyNumber,
        pageSize,
      });
      return `/accounts${
        accountId ? `/${accountId}/edit?from=${pageNumber}` : "?page=1"
      }&${query}`;
    },
    [
      pageNumber,
      pageSize,
      searchInput,
      sortAccountNumber,
      sortParticipantName,
      sortPolicyNumber,
    ]
  );

  const orderBy = useMemo(() => {
    if (sortAccountNumber) {
      return `REAccounts.REAccountNumber ${sortAccountNumber.toUpperCase()}, Workflows.WorkflowNumber DESC`;
    }
    if (sortPolicyNumber) {
      return `REAccounts.PolicyNumber ${sortPolicyNumber.toUpperCase()}, Workflows.WorkflowNumber DESC`;
    }
    if (sortParticipantName) {
      return `ParticipantName ${sortParticipantName.toUpperCase()}, REAccounts.REAccountNumber ASC, REAccounts.TaxCode ASC, Workflows.WorkflowNumber DESC`;
    }
    return "";
  }, [sortAccountNumber, sortParticipantName, sortPolicyNumber]);

  const getAccountsTableData = useCallback(async () => {
    const params = buildQueryParams({
      Limit: pageSize,
      Offset: offset,
      OrderBy: orderBy,
      search: searchInput,
    });

    const response = await get(`reaccounts/get?${params}`);
    setTableData(response);
  }, [offset, orderBy, pageSize, submitSearch]);

  useEffect(() => {
    if (submitSearch) {
      const pageReload = redirectUrl();
      history.push(pageReload);
    } else {
      getAccountsTableData();
    }
    setRefresh(false);
    setSubmitSearch(false);
    setExportOrderBy(orderBy);
    setExportSearch(searchInput);
  }, [getAccountsTableData, orderBy, refresh, submitSearch]);

  const columns = useMemo(
    () => [
      {
        id: "expanded-chevron",
        header: "",
        size: 0,
        cell: (tableRow) => {
          const { expandedRowIds, row } = tableRow || {};
          const className = `lnr ${
            expandedRowIds?.includes(row.id)
              ? "lnr-chevron-down"
              : "lnr-chevron-right"
          }`;
          return (
            <div className="expanded_chevron">
              <i className={className} />
            </div>
          );
        },
      },
      {
        id: "participantName",
        header: "Participant Name",
        accessorKey: "participantName",
        enableSorting: true,
        cell: (tableRow) => {
          const { participantName, planName, reAccountID } =
            tableRow?.row?.original || {};
          return (
            <ToolTip
              targetId={`participant_name_${reAccountID}`}
              accountId={reAccountID}
              label={planName}
              data={participantName}
            />
          );
        },
      },
      {
        id: "accountNumber",
        header: "Account #",
        accessorKey: "reAccountNumber",
        enableSorting: true,
        cell: (tableRow) => {
          const { reAccountNumber, productName, reAccountID } =
            tableRow?.row?.original || {};
          return (
            <ToolTip
              targetId={`account_number_${reAccountID}`}
              accountId={reAccountID}
              label={productName}
              data={reAccountNumber}
            />
          );
        },
      },
      {
        id: "policyNumber",
        header: "Policy #",
        enableSorting: true,
        accessorFn: (row) => row?.policyNumber || "-",
      },
      {
        id: "taxCode",
        header: "Tax Code",
        accessorKey: "taxCode",
      },
      {
        id: "accountStatus",
        header: "A / P Status",
        accessorFn: (row) => `${row?.accountStatus} / ${row?.policyStatus}`,
      },
      {
        id: "ssLastFour",
        header: "SS4",
        accessorFn: (row) => maskSSN(row?.ssLastFour),
      },
      {
        id: "actions",
        header: "",
        cell: (tableRow) => {
          const { reAccountID } = tableRow?.row?.original || {};
          const navigateToEditPage = redirectUrl(reAccountID);
          return (
            <div className="column-end pr-2 my-1">
              <Link to={navigateToEditPage}>
                <Badge className="btn-blue btn-badge align-middle">
                  View / Edit
                </Badge>
              </Link>
            </div>
          );
        },
      },
    ],
    [redirectUrl]
  );

  const pageProps = {
    total: recordCount,
    pageSize,
    setPageSize,
    pageNumber,
  };

  return (
    <Spinner requesting={isPending}>
      <AccountsTableSearch form={form} setSubmitSearch={setSubmitSearch} />
      <Table
        columns={columns}
        data={data}
        renderNestedTable={AccountSubTable}
        pageProps={pageProps}
      />
    </Spinner>
  );
};

export default AccountTable;
