import { useEffect } from "react";

import dayjs from "dayjs";
import { Card, CardBody, Button } from "reactstrap";

import { ExportToCsv, TableSearchInput, DateRangePicker } from "@atoms";
import { dateRangeOptions } from "@config/conf";
import useCsvExportButton from "@hooks/useCsvExportButton";
import useQueryParams from "@hooks/useQueryParams";

const commonClassName = "text-light table__search-input mb-0";
const dateRangeInputData = [
  {
    name: "startDate",
    placeholder: "Effective Start Date (MM/DD/YYYY)",
  },
  {
    name: "endDate",
    placeholder: "Effective End Date (MM/DD/YYYY)",
  },
];

const CashLedgerTableSearch = ({ setSubmitSearch, form }) => {
  const { control, watch, setValue } = form;

  const currentDateAndTime = dayjs();
  const { buildQueryParams } = useQueryParams();

  const { dateRangeInput, startDate, endDate } = watch();
  const shouldReset = dateRangeInput || startDate || endDate;
  const isCustomRange = dateRangeInput === "CustomRange";
  const csvDate = currentDateAndTime.format("YYYYMMDD_HHmmss");

  const {
    csvClickRef,
    csvData: { response: exportData = [] },
    getExport,
    loadingResponse,
  } = useCsvExportButton();

  const handleCsvExport = () => {
    const queryParams = buildQueryParams({
      dateRangeRequested: dateRangeInput,
      effectiveStartDate: startDate,
      effectiveEndDate: endDate,
    });

    getExport(`operations/cashledger/exportCashLedgerTable?${queryParams}`);
  };

  const handleClear = (e) => {
    e.preventDefault();
    setSubmitSearch(true);
    setValue("dateRangeInput", "Today");
    setValue("startDate", null);
    setValue("endDate", null);
  };

  useEffect(() => {
    if (!dateRangeInput) {
      setValue("dateRangeInput", "Today");
    } else if (dateRangeInput !== "CustomRange") {
      setValue("startDate", "");
      setValue("endDate", "");
    }
  }, [dateRangeInput]);

  const handleClick = () => {
    setSubmitSearch(true);
  };

  return (
    <Card className="pb-2">
      <CardBody className="py-2 px-0 table__search mb-0 form-inline">
        <TableSearchInput
          control={control}
          name="dateRangeInput"
          inputType="select"
          placeholder="Date Range"
          selectOptions={dateRangeOptions}
        />
        {isCustomRange &&
          dateRangeInputData.map(({ name, placeholder }) => (
            <DateRangePicker
              key={name}
              control={control}
              name={name}
              placeholder={placeholder}
              datePickerVariant="table"
            />
          ))}
        <Button color="blue" className={commonClassName} onClick={handleClick}>
          Filter
        </Button>
        <ExportToCsv
          csvData={exportData}
          csvRef={csvClickRef}
          fileName={`CashLedger_${csvDate}.csv`}
          getCsvExport={handleCsvExport}
          loadingResponse={loadingResponse}
          colorVariant="blue"
          className={commonClassName}
        />
        {shouldReset && (
          <Button
            type="reset"
            color="blue"
            className={commonClassName}
            onClick={handleClear}
          >
            Clear
          </Button>
        )}
      </CardBody>
    </Card>
  );
};

export default CashLedgerTableSearch;
