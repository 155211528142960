import { currentDate, formatDate, parsedDate } from "@lib/date";

export const getAsOfDate = (dob, policyDate) => {
  const currentCalenderYear = currentDate.year();
  const birthdate = parsedDate(dob);
  const policyIssueDateMoment = policyDate ? parsedDate(policyDate) : null;
  const lastBirthday = birthdate.year(currentCalenderYear).isAfter(currentDate)
    ? birthdate.year(currentCalenderYear - 1)
    : birthdate.year(currentCalenderYear);
  const resultDate = policyIssueDateMoment?.isAfter(lastBirthday)
    ? policyIssueDateMoment
    : lastBirthday;

  return formatDate(resultDate);
};
