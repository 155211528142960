import { useForm } from "react-hook-form";
import { Container } from "reactstrap";

import ProductFactorForm from "./ProductFactorForm";

const defaultValues = {
  factorName: "",
  joint: true,
  jointPercent: "0",
  refundable: true,
  productName: "",
  deferralYears: "0",
  selectable: true,
  status: "Active",
};

const AddProductFactor = (props) => {
  const {
    match: {
      params: { id: productID },
    },
  } = props;
  const productFactorForm = useForm({
    mode: "onChange",
    defaultValues,
  });

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3">Product Factor Detail</h3>
      <ProductFactorForm form={productFactorForm} productID={productID} />
    </Container>
  );
};

export default AddProductFactor;
