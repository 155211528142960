import { useEffect, useMemo, useState } from "react";

import { Input, Card, CardBody } from "reactstrap";

import { TableSearchInput, DateRangePicker } from "@atoms";
import { dateRangeOptions, progressStatusOptions } from "@config/conf";
import { filterOptions } from "@containers/components/helpers";
import useApiFetch from "@hooks/useApiFetch";

const commonClass = "btn-w text-light table__search table__search-input mb-0";

const dateRangeInputData = [
  {
    name: "effectiveStartDate",
    placeholder: "Effective Start Date (MM/DD/YYYY)",
  },
  {
    name: "effectiveEndDate",
    placeholder: "Effective End Date (MM/DD/YYYY)",
  },
];

const initialValues = {
  dateRange: "AllEntries",
  effectiveStartDate: null,
  effectiveEndDate: null,
  searchWorkflow: "",
  workflowStatus: "In Progress",
  custodianID: "",
};

const PurchasesWithdrawalsSearch = ({ setSubmitSearch, form }) => {
  const { get } = useApiFetch();
  const { control, watch, setValue, reset } = form;
  const [custodiansData, setCustodianData] = useState([]);

  const values = watch();
  const { dateRange } = values;

  const getCustodians = async () => {
    const response = await get("custodians/get");
    setCustodianData(response?.data);
  };

  useEffect(() => {
    getCustodians();
  }, []);

  useEffect(() => {
    if (!dateRange) {
      setValue("dateRange", "Today");
    } else if (dateRange !== "CustomRange") {
      setValue("effectiveStartDate", null);
      setValue("effectiveEndDate", null);
    }
  }, [dateRange]);

  const handleClear = (e) => {
    e.preventDefault();
    reset(initialValues);
    setSubmitSearch(true);
  };

  const handleClick = () => {
    setSubmitSearch(true);
  };

  const shouldShowClearButton = Object.values(values)?.filter(Boolean)?.length;

  const { inputs, button } = useMemo(() => {
    const custodianTypeOptions = filterOptions(
      custodiansData,
      "custodianID",
      "custodianName"
    );

    return {
      inputs: [
        {
          name: "searchWorkflow",
          placeholder: "WF#",
          maxLength: 20,
        },
        {
          name: "workflowStatus",
          inputType: "select",
          selectOptions: progressStatusOptions,
          firstFieldEmpty: true,
        },
        {
          name: "custodianID",
          inputType: "select",
          selectOptions: custodianTypeOptions,
          firstFieldEmpty: true,
        },
      ],
      button: [
        {
          type: "submit",
          className: `btn-blue ${commonClass}`,
          value: "Filter",
          onClick: handleClick,
        },
        ...(shouldShowClearButton
          ? [
              {
                type: "reset",
                className: commonClass,
                value: "Clear",
                onClick: handleClear,
              },
            ]
          : []),
      ],
    };
  }, [custodiansData, shouldShowClearButton]);

  return (
    <Card className="pb-2">
      <CardBody className="py-2 px-0 table__search mb-0 form-inline">
        <TableSearchInput
          control={control}
          name="dateRange"
          inputType="select"
          selectOptions={dateRangeOptions}
        />
        {dateRange === "CustomRange"
          ? dateRangeInputData?.map(({ name, placeholder }) => (
              <DateRangePicker
                key={name}
                control={control}
                name={name}
                placeholder={placeholder}
                datePickerVariant="table"
              />
            ))
          : null}
        {inputs.map((item) => (
          <TableSearchInput control={control} {...item} key={item?.name} />
        ))}
        {button.map((item) => (
          <Input key={item?.value} {...item} />
        ))}
      </CardBody>
    </Card>
  );
};

export default PurchasesWithdrawalsSearch;
